import React, { useContext } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  TextField,
  Grid,
  MenuItem,
  InputAdornment,
  Pagination,
  Stack,
  Backdrop,
  useTheme,
  Typography,
  colors,
} from "@mui/material";
import dayjs from "dayjs";
import DownloadIcon from "@mui/icons-material/Download";
import { useLocation, useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { PAGE_SIZE } from "../../Services/common/const";
import Title from "../../Components/Title";
import { PreferencesContext } from "../../PreferencesContext";
import MissingConsumer from "../../Components/MissingConsumer";
import {
  ExportFilesFunction,
  numberFormat,
  numberFormatWithCrDr,
} from "../../shared/CommonFuctions";
import { BorderColor, KeyboardArrowDownSharp } from "@mui/icons-material";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import SplashComponent from "../../Navigation/splash";
import { AlertType } from "../../shared/Modal/AlertType";
import { EncryptDecryptService } from "../../Services/common/EncryptionService";
import { StyledMenu } from "../../Components/StyleMenus";
import { format } from "date-fns";
import CustomDateRangePicker from "../../Components/CustomDateRange";
import { getEndDate, getStartDate } from "../../shared/CommonFuctions";
import {
  GetOutstandingPayableSummaryReport,
  GetOutstandingReceivableSummaryReport,
  GetSaleSummaryByCustomerReport,
} from "../../Services/ReportService";
import {
  GetOutstandingPayableSummaryList,
  GetOutstandingReceivableSummaryList,
} from "../../Services/Register";
import MultiSelect from "../../Components/MultiSelect";
import { GetBuyerList } from "../../Services/BuyerService";
import { GetSupplierList } from "../../Services/SupplierService";
import Select from "react-select";

interface ISupplier {
  supplierId: number;
  consumerId: number;
  firmId: number;
  organizationName: string;
  firstName: string;
  lastName: string;
  fullName: string;
  address1: string;
  address2: string;
  city: string;
  code: string;
  contactNumber: string;
  state: number;
  stateName: string;
  stateCode: string;
  country: number;
  email: string;
  taxNumber: string;
  type: number;
  label: string;
  value: string;
}

const parameters: any = {
  consumerId: 25,
  branchId: 1,
  pageIndex: 1,
  pageSize: PAGE_SIZE,
  sortColumn: "",
  sortDirection: "",
  searchValue: "",
  lessThanValue: "",
};
type Order = "asc" | "desc" | "";

interface Data {
  saleNumber: string;
  staff_code: string;
  sale_Id: Number;
  fullName: string;
}
const saleList: Data = {
  saleNumber: "",
  staff_code: "",
  sale_Id: 0,
  fullName: "",
};
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const OutstandingPayableSummary = () => {
  const theme = useTheme();
  let navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(PAGE_SIZE);
  const [value, setValue] = useState("");
  const { userContext, updateUserContext } =
    React.useContext(PreferencesContext);
  const [isLoading, setLoading] = React.useState(false);
  const pageName = "Purchase Payable Summary";
  const [dense, setDense] = React.useState(false);
  const [page_Index, setPage_Index] = useState(1);
  const [counData, setCounData] = useState(0);
  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
  const [Curr_page, setCurrPage] = React.useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [pageStartIndex, setPageStartIndex] = useState(1);
  const [pageEndIndex, setPageEndIndex] = useState(1);
  const [param, setParam] = useState(parameters);
  // const [toDate, setToDate] = React.useState<any>(getEndDateOnInit(userContext));
  // const [fromDate, setFromDate] = React.useState<any>(getStartDateOnInit(userContext));
  const [fromDate, setFromDate] = React.useState<any>(
    userContext.financialYearStartDate
  );
  const [toDate, setToDate] = React.useState<any>(
    userContext.financialYearEndDate
  );
  const matches = useMediaQuery("(max-width:710px)");
  const [order, setOrder] = React.useState<Order>("");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("none");
  const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [setselectedBuyerData, setSetselectedBuyerData] = useState<any>([]);
  const edService = new EncryptDecryptService();
  const openExportType = Boolean(anchorEl2);
  const handleCloseExportType = () => {
    setAnchorEl2(null);
  };
  const [selectedSuppliersId, setSelectedSupplierId] = React.useState<any>([]);
  const [selectedSupplierIdData, setSelectedSupplierIdData] =
    React.useState<any>([]);
  const [sourceSupplierCollection, setsourceSupplierCollection] =
    React.useState(() => {
      let coll: ISupplier[] = [];
      return coll;
    });
  const [nature, setNature] = useState("");
  const [reportType, setReportType] = useState("");

  const [totalAmount, setTotalAmount] = useState({
    closingBalance: 0,
    debit: 0,
    credit: 0,
    // kasar: 0,
  });

  const natures = [
    { label: "Cr", value: "CR" },
    { label: "Dr", value: "DR" },
  ];

  const reportTypes = [
    { label: "Payments Only", value: "PAYMENT_ONLY" },
    { label: "Bank Only", value: "BANK_ONLY" },
    { label: "Cash Only", value: "CASH_ONLY" },
  ];

  // const [fromDate, setFromDate] = React.useState<any>();
  // const [toDate, setToDate] = React.useState<any>();
  const handleClickExportType = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl2(event.currentTarget);
  };
  const [resultData, setResultData] = useState<any>(saleList);

  async function handleMoveToSale(saleId: any) {
    try {
      let encSaletId: any = edService.encrypt(saleId);
      navigate(
        {
          pathname: "/sale-by-customer",
          search: `?q=${encodeURIComponent(encSaletId)}`,
        },
        {
          // state:  { title: ""}
        }
      );
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage:
          typeof e === "string" ? (e as string) : (e?.message as string),
        alertType: AlertType.Error,
      });
    }
  }
  async function handleMoveToOutstandingReceivable(supplierId: any) {
    try {
      let encsupplierId: any = edService.encrypt(supplierId);
      navigate(
        {
          pathname: "/purchase-register",
          search: `?q=${encodeURIComponent(encsupplierId)}`,
        },
        {
          // state:  { title: ""}
        }
      );
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage:
          typeof e === "string" ? (e as string) : (e?.message as string),
        alertType: AlertType.Error,
      });
    }
  }
  const handleChange = (object: any) => {
    if (object?.length === 0) setResultData([]);
    setSelectedSupplierId(object);
    let tempSupplierIdData: any = [];
    object.forEach((x) => {
      if (x?.value === "*") {
        return;
      } else {
        let supplierIds = x.supplierId;
        x.supplierId = supplierIds;
        tempSupplierIdData.push(supplierIds.toString());
      }
    });
    setSelectedSupplierIdData(tempSupplierIdData);
  };
  async function fetchOutstandingPayableList() {
    let params = new URLSearchParams();
    params.append("consumerId", userContext.consumerId.toString());
    params.append("firmId", userContext.firmId.toString());
    params.append("branchId", userContext.branchId.toString());
    params.append("pageIndex", page_Index.toString());
    params.append("pageSize", param.pageSize);
    if (selectedSupplierIdData !== null) {
      params.append("supplierIds", selectedSupplierIdData);
    }
    params.append("searchValue", param.searchValue);
    params.append("financialYear", userContext.financialYear);
    params.append("nature", nature);
    params.append("type", reportType);
    params.append("customerId", setselectedBuyerData);
    params.append("startDate", format(new Date(fromDate), "yyyy-MM-dd"));
    params.append("endDate", format(new Date(toDate), "yyyy-MM-dd"));

    setLoading(true);
    try {
      const result: any = await GetOutstandingPayableSummaryList(params);
      let endIndex: any;
      if (
        result &&
        result.data.data &&
        result.data.data.outstandingPayableSummaryCollection
      ) {
        let resultData = result.data.data;
        let outstandingPayableSummaryCollection =
          resultData.outstandingPayableSummaryCollection;
        let dataCount = resultData.totalCount;

        let showDataCount = Math.ceil(dataCount / PAGE_SIZE);
        let startIndex = (page_Index - 1) * param.pageSize + 1;
        endIndex =
          (page_Index - 1) * param.pageSize +
          (outstandingPayableSummaryCollection
            ? outstandingPayableSummaryCollection.length
            : 0);

        setTotalAmount({
          closingBalance: resultData?.totalAmount || 0,
          credit: resultData?.totalPaidAmount || 0,
          debit: resultData?.totalDueAmount || 0,
          // kasar: resultData?.totalKasarAmount || 0,
        });

        setResultData(outstandingPayableSummaryCollection);
        setCounData(showDataCount);
        setPageStartIndex(startIndex);
        setPageEndIndex(endIndex);
        setTotalCount(dataCount);
      }
    } catch (e) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage:
          typeof e === "string" ? (e as string) : (e?.message as string),
        alertType: AlertType.Error,
      });
    }

    setLoading(false);
  }
  async function getSuppliers(onInit, searchValue) {
    let params = new URLSearchParams();
    let supplierCollectionData: any;
    params.append("consumerId", userContext.consumerId.toString());
    params.append("firmId", userContext.firmId.toString());
    params.append("branchId", userContext.branchId.toString());
    params.append("pageIndex", parameters.pageIndex.toString());
    params.append("pageSize", "1000");
    params.append("sortColumn", parameters.sortColumn);
    params.append("sortDirection", parameters.sortDirection);
    params.append("searchValue", searchValue);
    params.append("mode", "");
    params.append("financialYear", userContext.financialYear?.toString());
    try {
      const result: any = await GetSupplierList(params);
      if (result) {
        supplierCollectionData = result?.data?.data?.supplierCollection;
        if (supplierCollectionData)
          setsourceSupplierCollection(supplierCollectionData);
      }
    } catch (error) {
      console.log(error);
    }
    return supplierCollectionData;
  }
  async function openOutstandingReceivableSummaryPopup(type: any) {
    setAnchorEl2(null);
    setLoading(true);
    let params = new URLSearchParams();
    var attachmentName = "";
    if (type === "pdf") {
      attachmentName =
        `SaleSummaryByCustomerReport` +
        format(new Date(fromDate), "_yyyyMMdd") +
        format(new Date(toDate), "_yyyyMMdd") +
        ".pdf";
    } else {
      attachmentName =
        "SaleSummaryByCustomerReport" +
        format(new Date(fromDate), "_yyyyMMdd") +
        format(new Date(toDate), "_yyyyMMdd") +
        ".xls";
    }
    params.append("reportName", "OutstandingPayableSummary");
    params.append("reportType", type);
    params.append("consumerId", userContext.consumerId.toString());
    params.append("branchId", userContext.branchId.toString());
    params.append("firmId", userContext.firmId.toString());
    if (selectedSupplierIdData !== null) {
      params.append("supplierIds", selectedSupplierIdData);
    }
    params.append("financialYear", userContext.financialYear.toString());
    params.append("searchValue", param.searchValue);
    params.append("mode", "EXPORT");
    params.append("nature", nature);
    params.append("type", reportType);
    params.append("startDate", format(new Date(fromDate), "yyyy-MM-dd"));
    params.append("endDate", format(new Date(toDate), "yyyy-MM-dd"));
    params.append("fileName", attachmentName);
    params.append("isForDownload", "1");
    try {
      const result: any = await GetOutstandingPayableSummaryReport(params);
      if (result && result.data && result.data.data) {
        var fileContent = result?.data?.data?.fileData;
        var attachmentData = fileContent;
        ExportFilesFunction(attachmentName, type, attachmentData);
        setLoading(false);
      }
      setLoading(false);
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage:
          typeof e === "string" ? (e as string) : (e?.message as string),
        alertType: AlertType.Error,
      });
    }
    setLoading(false);
  }

  function searchValue(val: any) {
    if (val === "") {
      setValue("");
    }
    setPage_Index(1);
    setParam({ ...param, searchValue: val.toString(), pageIndex: 1 });
  }

  interface EnhancedTableProps {
    order: Order;
    orderBy: String;
    rowCount: number;
  }

  const selectStyles = () => {
    return {
      control: (basestyle, state) => ({
        ...basestyle,
        // height: "44px",
        border: state.isFocused ? "1px solid black" : "1px solid $grey-100",
        boxShadow: "none",
        "&:hover": {
          border: "1px solid black",
        },
      }),
      placeholder: (basestyle, state) => ({
        ...basestyle,
        color:
          state.hasValue || state.selectProps.inputValue
            ? state.isFocused
              ? "#0b0b45"
              : "gray"
            : "gray",
        overflow: "hidden",
        backgroundColor:
          state.hasValue || state.selectProps.inputValue
            ? "white"
            : "transparant",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        position:
          state.hasValue || state.selectProps.inputValue
            ? "absolute"
            : "static",
        top: state.hasValue || state.selectProps.inputValue ? -15 : "10%",
        transition: "top 0.3s, font-size 0.1s",
        fontSize: (state.hasValue || state.selectProps.inputValue) && 10,
        paddingLeft:
          state.hasValue || state.selectProps.inputValue ? "3px" : "0px",
        paddingRight:
          state.hasValue || state.selectProps.inputValue ? "3px" : "0px",
      }),
      option: (basestyle, state) => ({
        ...basestyle,
        color: state.isSelected ? "black" : "black",
        border: "1px solid #f1f6fe",
        // backgroundColor: "red !important",
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        overflow:
          state.hasValue || state.selectProps.inputValue ? "visible" : "hidden",
      }),
      dropdownIndicator: (base, state) => ({
        ...base,
        color: "#adb5bd",
        "&:hover": {
          color: "black",
        },
      }),

      indicatorSeparator: (base, state) => ({
        ...base,
        backgroundColor: "#adb5bd",
      }),
      clearIndicator: (base, state) => ({
        ...base,
        color: "#adb5bd",
        "&:hover": {
          color: "black",
        },
      }),
      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    };
  };
  const handleChangePageNew = (
    event: React.ChangeEvent<unknown>,
    value: number
    //values: number
  ) => {
    if (Curr_page !== value) {
      setCurrPage(value);
      setPage_Index(value);
      setParam({ ...param, pageIndex: value });
    }
  };

  useEffect(() => {
    fetchOutstandingPayableList();
  }, [param, selectedSupplierIdData, fromDate, toDate, nature, reportType]);
  useEffect(() => {
    getSuppliers(true, "");
  }, []);

  function EnhancedTableHead(props: EnhancedTableProps) {
    return (
      // checkBox for Header
      <TableHead className="table-header-color">
        <TableRow>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              minWidth: "80px",
            }}
            align="left"
          >
            {"Organization Name"}
          </TableCell>

          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              minWidth: "80px",
            }}
            align="left"
          >
            {"Supplier Name"}
          </TableCell>

          {/* <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              minWidth: "80px",
            }}
            align="right"
          >
            {"Kasar ₹"}
          </TableCell> */}

          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              minWidth: "80px",
            }}
            align="right"
          >
            {"Debit ₹"}
          </TableCell>

          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              minWidth: "80px",
            }}
            align="right"
          >
            {"Credit ₹"}
          </TableCell>

          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              minWidth: "80px",
            }}
            align="right"
          >
            {"Closing Balance ₹"}
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          backgroundColor: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isLoading}
      >
        <SplashComponent />
      </Backdrop>
      <Title pageTitle={pageName} />

      {userContext.consumerId > 0 ? (
        <Box
          className="boxContainer"
          sx={{
            "& > :not(style)": {
              width: "100%",
            },
          }}
        >
          <Grid
            className="title-button-responsive"
            item
            xs={6}
            md={3}
            sx={{
              display: "flex !important",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h4 className="page-title">{pageName}</h4>
            {!matches && (
              <>
                <Grid
                  item
                  xs={6}
                  md={3}
                  marginRight={0}
                  textAlign="end"
                  paddingTop="0px"
                >
                  <Box sx={{ display: "flex", gap: 2, alignItems: "end" }}>
                    {/* <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography>Kasar ₹</Typography>
                      <Box className={"inner-tabs tab-all"}>
                        ₹{numberFormat(totalAmount?.kasar)}
                      </Box>
                    </Box> */}
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography>Debit ₹</Typography>
                      <Box className={"inner-tabs tab-all"}>
                        ₹{numberFormat(totalAmount?.debit)}
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography>Credit ₹</Typography>
                      <Box className={"inner-tabs tab-all"}>
                        ₹{numberFormat(totalAmount?.credit)}
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography>Closing Balance ₹</Typography>
                      <Box className={"inner-tabs tab-all"}>
                        ₹
                        {numberFormatWithCrDr(
                          totalAmount?.closingBalance,
                          "CR"
                        )}
                      </Box>
                    </Box>
                    <Box>
                      <Button
                        // color="primary"
                        variant="outlined"
                        onClick={() => navigate(-1)}
                        size="medium"
                        startIcon={<KeyboardBackspaceRoundedIcon />}
                      >
                        Back
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
          {matches && (
            <>
              <Grid item xs={12} md={3}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  {/* <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography>Kasar ₹</Typography>
                    <Box className={"inner-tabs tab-all"}>
                      ₹{numberFormat(totalAmount?.kasar)}
                    </Box>
                  </Box> */}
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography>Debit ₹</Typography>
                    <Box className={"inner-tabs tab-all"}>
                      ₹{numberFormat(totalAmount?.debit)}
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography>Credit ₹</Typography>
                    <Box className={"inner-tabs tab-all"}>
                      ₹{numberFormat(totalAmount?.credit)}
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography>Closing Balance ₹</Typography>
                    <Box className={"inner-tabs tab-all"}>
                      ₹{numberFormat(totalAmount?.closingBalance)}
                    </Box>
                  </Box>
                  <Box>
                    <Button
                      className="button-responsive-stock"
                      // color="primary"
                      variant="outlined"
                      onClick={() => navigate(-1)}
                      size="medium"
                      startIcon={<KeyboardBackspaceRoundedIcon />}
                    >
                      Back
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </>
          )}

          <Grid className="paper top-space">
            <Paper elevation={4} className="paper-container">
              <Grid item xs={12} sm={4} md={3} lg={2} xl={2}></Grid>
              <Grid
                container
                spacing={1}
                sx={{ marginTop: "10px" }}
                className="search-space"
              >
                {/* <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
                  <TextField
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        searchValue(value);
                      }
                    }}
                    sx={{ paddingLeft: "0px !important", width: "100%" }}
                    className="button-responsive-stock"
                    placeholder="Search..."
                    type="text"
                    variant="outlined"
                    size="small"
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => searchValue(value)}>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),

                      endAdornment: value && (
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => searchValue("")}
                        >
                          <ClearIcon />
                        </IconButton>
                      ),
                    }}
                  />
                </Grid> */}
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                  <span
                    className="d-inline-block"
                    data-toggle="popover"
                    data-trigger="focus"
                    data-content="Please selecet account(s)"
                  >
                    <MultiSelect
                      placeholderLabel="Vendors"
                      options={sourceSupplierCollection}
                      onChange={handleChange}
                      value={selectedSuppliersId}
                      isSelectAll={true}
                    />
                  </span>
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                  <Grid
                    sx={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                      gap: "16px",
                      flexDirection: matches && "column",
                    }}
                  >
                    <Select
                      className="sale-receivable-summary-filter"
                      id={"Type"}
                      isClearable
                      isSearchable={false}
                      placeholder={"Select Type"}
                      options={reportTypes}
                      styles={selectStyles()}
                      // styles={colourStyles}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: "#ebf2fe", //active
                          primary25: "#f1f6fe", //hover
                          primary50: "#f1f6fe", //clicable
                        },
                      })}
                      onChange={(e) => {
                        setReportType(e?.value || "");
                      }}
                      value={reportTypes?.find((x) => x?.value === reportType)}
                    />
                    <Select
                      className="button-responsive-stock"
                      id={"nature"}
                      isClearable
                      isSearchable={false}
                      placeholder={"Nature"}
                      options={natures}
                      styles={selectStyles()}
                      // styles={colourStyles}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: "#ebf2fe", //active
                          primary25: "#f1f6fe", //hover
                          primary50: "#f1f6fe", //clicable
                        },
                      })}
                      onChange={(e) => {
                        setNature(e?.value || "");
                      }}
                      value={natures?.find((x) => x?.value === nature)}
                    />
                    <CustomDateRangePicker
                      className="button-responsive-stock"
                      fromDate={fromDate}
                      setFromDate={setFromDate}
                      toDate={toDate}
                      setToDate={setToDate}
                      minDate={dayjs(userContext.financialYearStartDate)}
                      maxDate={dayjs(userContext.financialYearEndDate)}
                    />
                    <Button
                      className="button-responsive-stock"
                      aria-controls={
                        openExportType ? "demo-customized-menu" : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={openExportType ? "true" : undefined}
                      disabled={resultData?.length <= 0 ? true : false}
                      variant="outlined"
                      disableElevation
                      onClick={handleClickExportType}
                      startIcon={<DownloadIcon />}
                      endIcon={<KeyboardArrowDownSharp />}
                    >
                      Export
                    </Button>
                    <StyledMenu
                      id="demo-customized-menu"
                      MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                      }}
                      anchorEl={anchorEl2}
                      open={openExportType}
                      onClose={handleCloseExportType}
                    >
                      <MenuItem
                        disableRipple
                        onClick={() => {
                          openOutstandingReceivableSummaryPopup("pdf");
                        }}
                      >
                        PDF
                      </MenuItem>
                      <MenuItem
                        disableRipple
                        onClick={() => {
                          openOutstandingReceivableSummaryPopup("Excel");
                        }}
                      >
                        EXCEL
                      </MenuItem>
                    </StyledMenu>
                  </Grid>
                </Grid>
              </Grid>

              <TableContainer>
                <Table
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                >
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    rowCount={resultData?.length}
                  />
                  {resultData?.length > 0 && (
                    <TableBody>
                      {stableSort(resultData, getComparator(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item: any, index: any) => {
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={index + 1}
                            >
                              <TableCell>
                                <Button
                                  disableRipple
                                  color="inherit"
                                  onClick={() => {
                                    handleMoveToOutstandingReceivable(
                                      item?.supplierId
                                    );
                                  }}
                                  className="manuButton-link"
                                  style={{ backgroundColor: "transparent" }}
                                >
                                  <span
                                    className="text-link"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {item?.organizationName}
                                  </span>
                                </Button>
                              </TableCell>

                              {/* <TableCell
                                sx={{
                                  display: {
                                    xs: "table-cell",
                                    sm: "table-cell",
                                    md: "table-cell",
                                  },
                                }}
                                align="left"
                              >
                                {item?.organizationName}
                              </TableCell> */}
                              <TableCell
                                sx={{
                                  display: {
                                    xs: "table-cell",
                                    sm: "table-cell",
                                    md: "table-cell",
                                  },
                                }}
                                align="left"
                              >
                                {item?.supplierName}
                              </TableCell>
                              {/* <TableCell
                                className={item?.kasar < 0 ? "danger" : ""}
                                sx={{
                                  display: {
                                    xs: "table-cell",
                                    sm: "table-cell",
                                    md: "table-cell",
                                  },
                                  fontWeight: 600,
                                }}
                                align="right"
                              >
                                {numberFormat(item?.kasar)}
                              </TableCell> */}
                              <TableCell
                                className={item?.debit < 0 ? "danger" : ""}
                                sx={{
                                  display: {
                                    xs: "table-cell",
                                    sm: "table-cell",
                                    md: "table-cell",
                                  },
                                  fontWeight: 600,
                                }}
                                align="right"
                              >
                                {numberFormat(item?.debit)}
                              </TableCell>
                              <TableCell
                                className={item?.credit < 0 ? "danger" : ""}
                                sx={{
                                  display: {
                                    xs: "table-cell",
                                    sm: "table-cell",
                                    md: "table-cell",
                                  },
                                  fontWeight: 600,
                                }}
                                align="right"
                              >
                                {numberFormat(item?.credit)}
                              </TableCell>
                              <TableCell
                                className={
                                  item?.closingBalance < 0 ? "danger" : ""
                                }
                                sx={{
                                  display: {
                                    xs: "table-cell",
                                    sm: "table-cell",
                                    md: "table-cell",
                                  },
                                  fontWeight: 600,
                                }}
                                align="right"
                              >
                                {numberFormatWithCrDr(
                                  item?.closingBalance,
                                  "CR"
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}

                      {resultData?.length <= 0 && (
                        <TableRow hover tabIndex={-1} key={"0"}>
                          <TableCell colSpan={6} align="center">
                            No record(s) found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  )}
                </Table>
                {resultData?.length > 0 && (
                  <Grid
                    container
                    paddingTop={1}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Grid item xs={12} sm={6} className="pagination-label">
                      {pageStartIndex} - {pageEndIndex} of {totalCount}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack
                        component="div"
                        alignItems={mobileScreen ? " center" : "end"}
                        justifyContent={"center"}
                      >
                        <Pagination
                          color="primary"
                          count={counData}
                          page={page_Index}
                          onChange={handleChangePageNew}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                )}
              </TableContainer>
            </Paper>
          </Grid>
        </Box>
      ) : (
        <Box
          className="boxContainer"
          sx={{
            "& > :not(style)": {
              width: "100%",
            },
          }}
        >
          <MissingConsumer consumerId={userContext.consumerId} />
        </Box>
      )}
    </>
  );
};

export default OutstandingPayableSummary;
