import * as React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  TextField,
  Grid,
  Pagination,
  Stack,
  Backdrop,
  MenuItem,
} from "@mui/material";
import { StyledMenu } from "../../Components/StyleMenus";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { InputAdornment } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useState, useEffect, useContext } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { PAGE_SIZE } from "../../Services/common/const";
import Title from "../../Components/Title";
import { PreferencesContext } from "../../PreferencesContext";
import { AlertType } from "../../shared/Modal/AlertType";
import MissingConsumer from "../../Components/MissingConsumer";
import { EncryptDecryptService } from "../../Services/common/EncryptionService";
import SplashComponent from "../../Navigation/splash";
import { ExportFilesFunction, numberFormat } from "../../shared/CommonFuctions";
import { GetStockByWarehouseList } from "../../Services/StockService";
import DownloadIcon from "@mui/icons-material/Download";
import { KeyboardArrowDownSharp, SwitchCamera } from "@mui/icons-material";
import { GetStockWarehouseByReport } from "../../Services/ReportService";

function EnhancedTableHead() {
  return (
    // checkBox for Header
    <TableHead className="table-header-color">
      <TableRow>
        <TableCell
          sx={{
            display: {
              xs: "table-cell",
              sm: "table-cell",
              md: "table-cell",
              lg: "table-cell",
            },
            fontWeight: "bold",
            width: "20%",
          }}
          align="left"
        >
          {"Name"}
        </TableCell>

        <TableCell
          sx={{
            display: {
              xs: "table-cell",
              sm: "table-cell",
              md: "table-cell",
              lg: "table-cell",
            },
            fontWeight: "bold",
            // width: "75%",
          }}
          align="right"
        >
          {"Opening Quantity"}
        </TableCell>

        <TableCell
          sx={{
            display: {
              xs: "table-cell",
              sm: "table-cell",
              md: "table-cell",
              lg: "table-cell",
            },
            fontWeight: "bold",
            // width: "75%",
          }}
          align="right"
        >
          {"Inward Quantity"}
        </TableCell>

        <TableCell
          sx={{
            display: {
              xs: "table-cell",
              sm: "table-cell",
              md: "table-cell",
              lg: "table-cell",
            },
            fontWeight: "bold",
            // width: "75%",
          }}
          align="right"
        >
          {"Outward Quantity"}
        </TableCell>

        <TableCell
          sx={{
            display: {
              xs: "table-cell",
              sm: "table-cell",
              md: "table-cell",
              lg: "table-cell",
            },
            fontWeight: "bold",
            // width: "75%",
          }}
          align="right"
        >
          {"Quantity"}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

const edService = new EncryptDecryptService();

const parameters: any = {
  pageIndex: 1,
  pageSize: PAGE_SIZE,
  sortColumn: "",
  sortDirection: "",
  searchValue: "",
};

const StockByWarehouseReport = () => {
  const matches = useMediaQuery("(max-width:599px)");
  const mediumDevice = useMediaQuery("(max-width:899px)");
  const [param, setParam] = useState(parameters);
  const [anchorEl, setAnchorEl3] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const [totalCount, setTotalCount] = useState(0);
  const [pageStartIndex, setPageStartIndex] = useState(1);
  const [pageEndIndex, setPageEndIndex] = useState(1);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [value, setValue] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE);
  const [resultData, setResultData] = useState<any>([]);
  const [page_Index, setPage_Index] = useState(1);
  const [counData, setCounData] = useState(0);
  const { userContext, updateUserContext } = useContext(PreferencesContext);
  const [isLoading, setLoading] = useState(false);
  const [isAllowAdd, setAllowAdd] = useState(true);
  const [Curr_page, setCurrPage] = useState(1);
  const [showLogo, setShowLogo] = useState("");
  const [logoselectedFile, setLogoSelectedFile] = useState<any>();
  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
  const openExportType = Boolean(anchorEl2);
  const handleChangePageNew = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    if (Curr_page !== value) {
      setCurrPage(value);
      setPage_Index(value);
      setParam({ ...param, pageIndex: value });
      fetchWarehouseList();
    }
  };
  const saveLogoFileSelected = (e) => {
    setShowLogo(e[0]);
    setLogoSelectedFile(e[0]);
  };
  const handleClickExportType = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleCloseExportType = () => {
    setAnchorEl2(null);
  };

  function searchValue(val: any) {
    if (val === "") {
      setValue("");
    }
    setPage_Index(1);
    setParam({ ...param, searchValue: val.toString(), pageIndex: 1 });
  }
  let navigate = useNavigate();

  async function fetchWarehouseList() {
    try {
      setLoading(true);
      if (
        userContext != null &&
        userContext.consumerId != null &&
        userContext.consumerId > 0
      ) {
        setAllowAdd(true);
      } else {
        setAllowAdd(false);
      }
      let params = new URLSearchParams();
      params.append("consumerId", userContext.consumerId.toString());
      params.append("firmId", userContext.firmId.toString());
      params.append("branchId", userContext.branchId.toString());
      params.append("pageIndex", page_Index.toString());
      params.append("pageSize", param.pageSize);
      params.append("sortColumn", param.sortColumn);
      params.append("sortDirection", param.sortDirection);
      params.append("searchValue", param.searchValue);
      params.append("financialYear", userContext.financialYear);
      params.append("mode", "");
      const result: any = await GetStockByWarehouseList(params);
      if (result && result.data.data) {
        let dataCount = result.data.data.totalCount;
        let WarehouseInfo = result.data?.data?.warehouseCollection;
        let showDataCount = Math.ceil(dataCount / PAGE_SIZE);
        setResultData(WarehouseInfo);
        setCounData(showDataCount);
        let startIndex = (page_Index - 1) * param.pageSize + 1;
        let endIndex =
          (page_Index - 1) * param.pageSize +
          (result.data.data.warehouseCollection
            ? result.data.data.warehouseCollection.length
            : 0);

        setPageStartIndex(startIndex);
        setPageEndIndex(endIndex);
        setTotalCount(dataCount);
      } else {
      }
      setLoading(false);
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage:
          typeof e === "string" ? (e as string) : (e?.message as string),
        alertType: AlertType.Error,
      });
    }
  }

  async function handleMoveToStock(warehouseId: any) {
    try {
      let encWarehouseId: any = edService.encrypt(warehouseId);
      navigate(
        {
          pathname: "/stock",
          search: `?q=${encodeURIComponent(encWarehouseId)}`,
        },
        {
          state: {
            title: "Edit Category",
            button: "Update",
            warehouseId: warehouseId,
            isAddPopUp: "false",
          },
        }
      );
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage:
          typeof e === "string" ? (e as string) : (e?.message as string),
        alertType: AlertType.Error,
      });
    }
  }

  async function openSalesReportPopup(type: any) {
    setAnchorEl2(null);
    setLoading(true);
    let params = new URLSearchParams();
    var attachmentName = "";
    if (type === "pdf") {
      attachmentName = `StockWarehouseWiseReport` + ".pdf";
    } else {
      attachmentName = "StockWarehouseWiseReport" + ".xls";
    }
    params.append("reportName", "StockWarehouseWiseReport");
    params.append("reportType", type);
    params.append("consumerId", userContext.consumerId.toString());
    params.append("branchId", userContext.branchId.toString());
    params.append("firmId", userContext.firmId.toString());
    params.append("financialYear", userContext.financialYear.toString());
    params.append("searchValue", param.searchValue);
    params.append("mode", "EXPORT");
    params.append("fileName", attachmentName);
    params.append("isForDownload", "1");
    try {
      const result: any = await GetStockWarehouseByReport(params);
      if (result && result.data && result.data.data) {
        var fileContent = result?.data?.data?.fileData;
        var attachmentData = fileContent;
        ExportFilesFunction(attachmentName, type, attachmentData);
        setLoading(false);
      }
      setLoading(false);
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage:
          typeof e === "string" ? (e as string) : (e?.message as string),
        alertType: AlertType.Error,
      });
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchWarehouseList();
    let e: any = document.getElementById("root");
    e.scrollIntoView({
      block: "start",
      behavior: "smooth",
      inline: "start",
    });
  }, [param]);

  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          backgroundColor: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isLoading}
      >
        <SplashComponent />
      </Backdrop>
      <Title pageTitle="Stock By Warehouse Report" />
      <Box
        className="boxContainer"
        sx={{
          "& > :not(style)": {
            width: "100%",
          },
        }}
      >
        {userContext.consumerId > 0 ? (
          <Grid>
            <Grid
              className="title-button-responsive"
              item
              xs={6}
              md={3}
              sx={{
                display: "flex !important",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h4 className="page-title">Stock by Warehouse</h4>
            </Grid>
            <Grid className="paper top-space">
              <Paper elevation={4} className="paper-container">
                <Grid container rowGap={1} className="search-space">
                  <Grid item xs={12} sm={9} md={8} lg={8} xl={8}>
                    <TextField
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          searchValue(value);
                        }
                      }}
                      placeholder="Search..."
                      type="text"
                      variant="outlined"
                      size="small"
                      onChange={(e) => setValue(e.target.value)}
                      value={value}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton onClick={() => searchValue(value)}>
                              {/* {" "} */}
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),

                        endAdornment: value && (
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => searchValue("")}
                          >
                            <ClearIcon />
                          </IconButton>
                        ),
                      }}
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={3} md={4} lg={4} xl={4}>
                  </Grid> */}
                  <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                    <Grid
                      sx={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        className="button-responsive-stock"
                        aria-controls={
                          openExportType ? "demo-customized-menu" : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={openExportType ? "true" : undefined}
                        disabled={resultData?.length <= 0 ? true : false}
                        variant="outlined"
                        disableElevation
                        onClick={handleClickExportType}
                        startIcon={<DownloadIcon />}
                        endIcon={<KeyboardArrowDownSharp />}
                        // sx={{ alignItems:'end', display:'flex',justifyContent:'end'}}
                      >
                        Export
                      </Button>
                    </Grid>

                    <StyledMenu
                      id="demo-customized-menu"
                      MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                      }}
                      anchorEl={anchorEl2}
                      open={openExportType}
                      onClose={handleCloseExportType}
                    >
                      <MenuItem
                        disableRipple
                        onClick={() => {
                          openSalesReportPopup("pdf");
                        }}
                      >
                        PDF
                      </MenuItem>
                      <MenuItem
                        disableRipple
                        onClick={() => {
                          openSalesReportPopup("Excel");
                        }}
                      >
                        EXCEL
                      </MenuItem>
                    </StyledMenu>
                  </Grid>
                </Grid>
                <TableContainer>
                  <Table
                    aria-labelledby="tableTitle"
                    size={dense ? "small" : "medium"}
                  >
                    <EnhancedTableHead />
                    <TableBody>
                      {resultData
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item: any, index: any) => {
                          return (
                            <TableRow hover key={item.warehouseId}>
                              <TableCell
                              // onClick={() =>
                              //   mediumDevice
                              //     ? handleMoveToStock(item.categoryId)
                              //     : null
                              // }
                              >
                                {item?.consumerId > 0 ? (
                                  <Button
                                    disableRipple
                                    color="inherit"
                                    onClick={() =>
                                      handleMoveToStock(item.warehouseId)
                                    }
                                    className="manuButton-link"
                                    style={{ backgroundColor: "transparent" }}
                                  >
                                    <span className="text-link">
                                      {item?.name}
                                    </span>
                                  </Button>
                                ) : (
                                  <span
                                    className="text-link"
                                    style={{ color: "#5156BE " }}
                                  >
                                    {item?.name}
                                  </span>
                                )}
                              </TableCell>

                              <TableCell
                                sx={{
                                  display: {
                                    xs: "table-cell",
                                    sm: "table-cell",
                                    md: "table-cell",
                                  },
                                }}
                                align="right"
                              >
                                {numberFormat(item?.opening)}
                              </TableCell>

                              <TableCell
                                sx={{
                                  display: {
                                    xs: "table-cell",
                                    sm: "table-cell",
                                    md: "table-cell",
                                  },
                                }}
                                align="right"
                              >
                                {numberFormat(item?.inward)}
                              </TableCell>

                              <TableCell
                                sx={{
                                  display: {
                                    xs: "table-cell",
                                    sm: "table-cell",
                                    md: "table-cell",
                                  },
                                }}
                                align="right"
                              >
                                {numberFormat(item?.outward)}
                              </TableCell>

                              <TableCell align="right">
                                {numberFormat(item?.quantity)}
                              </TableCell>
                            </TableRow>
                          );
                        })}

                      {resultData.length <= 0 ? (
                        <TableRow hover tabIndex={-1} key={"0"}>
                          <TableCell colSpan={6} align="center">
                            No record(s) found
                          </TableCell>
                        </TableRow>
                      ) : (
                        <></>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {resultData.length <= 0 ? (
                  <></>
                ) : (
                  <>
                    {matches ? (
                      <Grid
                        container
                        paddingTop={1}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Grid item xs={12} sm={6} className="pagination-label">
                          {pageStartIndex} - {pageEndIndex} of {totalCount}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Stack
                            component="div"
                            alignItems={matches ? "center" : "end"}
                            justifyContent={"center"}
                          >
                            <Pagination
                              color="primary"
                              count={counData}
                              page={page_Index}
                              onChange={handleChangePageNew}
                            />
                          </Stack>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid
                        container
                        paddingTop={1}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Grid item xs={6} className="pagination-label">
                          {pageStartIndex} - {pageEndIndex} of {totalCount}
                        </Grid>
                        <Grid item xs={6}>
                          <Stack
                            component="div"
                            alignItems={"end"}
                            justifyContent={"center"}
                          >
                            <Pagination
                              color="primary"
                              count={counData}
                              page={page_Index}
                              onChange={handleChangePageNew}
                            />
                          </Stack>
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}
              </Paper>
            </Grid>
          </Grid>
        ) : (
          <MissingConsumer consumerId={userContext.consumerId} />
        )}
      </Box>
    </>
  );
};

export default StockByWarehouseReport;
