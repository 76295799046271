import { get, post } from "./common/http";
import { BASE_URL } from "./common/const";



export const UserGroupMenuAccess = (param:any) =>
    get(`${BASE_URL}/api/UserGroupAccess/GetUserGroupMenuAccess?` + param,null)
export const GetUserGroupList = (param:any) =>
    get(`${BASE_URL}/api/UserGroupAccess/GetUserGroupList?` +param,null)
export const GetMenuAccess = (param:any) =>
    get(`${BASE_URL}/api/UserGroupAccess/GetMenuAccess?` + param,null)

export const saveUserMenuAccessData = (data: any) =>
    post(`${BASE_URL}/api/UserGroupAccess/Create`, data, true);