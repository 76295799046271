import React, { useContext } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  TextField,
  Grid,
  MenuItem,
  InputAdornment,
  Pagination,
  Stack,
  Backdrop,
  useTheme,
} from "@mui/material";

import DownloadIcon from "@mui/icons-material/Download";
import { useLocation, useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { PAGE_SIZE } from "../../../Services/common/const";
import Title from "../../../Components/Title";
import { PreferencesContext } from "../../../PreferencesContext";
import MissingConsumer from "../../../Components/MissingConsumer";
import {
  ExportFilesFunction,
  numberFormat,
} from "../../../shared/CommonFuctions";
import { KeyboardArrowDownSharp, SwitchCamera } from "@mui/icons-material";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import SplashComponent from "../../../Navigation/splash";
import { AlertType } from "../../../shared/Modal/AlertType";
import MultiSelectSim from "../../../Components/MultiSelectSimple";
import { EncryptDecryptService } from "../../../Services/common/EncryptionService";
import { GetProductListforBatchwiseListReport } from "../../../Services/CategoryProductService";
import { StyledMenu } from "../../../Components/StyleMenus";
import {
  GetSaleSummaryBySalePerson,
  GetStaffListForInvoice,
} from "../../../Services/SaleService";
import { format } from "date-fns";
import CustomDateRangePicker from "../../../Components/CustomDateRange";
import {
  getCurrentFinancialYear,
  getEndDate,
  getStartDate,
  getStartDateOnInit,
  getEndDateOnInit,
} from "../../../shared/CommonFuctions";
import { GetSaleSummaryBySalePersonReport } from "../../../Services/ReportService";
import { parametersForSalePage } from "../../../StaticData/common";
import { GetBuyerList } from "../../../Services/BuyerService";

const parameters: any = {
  consumerId: 25,
  branchId: 1,
  pageIndex: 1,
  pageSize: PAGE_SIZE,
  sortColumn: "",
  sortDirection: "",
  searchValue: "",
  lessThanValue: "",
};
type Order = "asc" | "desc" | "";

interface Data {
  saleNumber: string;
  staff_code: string;
  sale_Id: Number;
  fullName: string;
}
const saleList: Data = {
  saleNumber: "",
  staff_code: "",
  sale_Id: 0,
  fullName: "",
};
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const SaleSummaryBySalesPerson = () => {
  const theme = useTheme();
  let navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(PAGE_SIZE);
  const [value, setValue] = useState("");
  const { userContext, updateUserContext } =
    React.useContext(PreferencesContext);
  const [isLoading, setLoading] = React.useState(false);
  const [pageName, setPageName] = React.useState("Sales Details By Saleperson");
  const [dense, setDense] = React.useState(false);
  const [page_Index, setPage_Index] = useState(1);
  const [counData, setCounData] = useState(0);
  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
  const [Curr_page, setCurrPage] = React.useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [pageStartIndex, setPageStartIndex] = useState(1);
  const [pageEndIndex, setPageEndIndex] = useState(1);
  const [param, setParam] = useState(parameters);
  // const [toDate, setToDate] = React.useState<any>(getEndDateOnInit(userContext));
  // const [fromDate, setFromDate] = React.useState<any>(getStartDateOnInit(userContext));

  const [fromDate, setFromDate] = React.useState<any>(
    userContext.financialYearStartDate
  );
  const [toDate, setToDate] = React.useState<any>(
    userContext.financialYearEndDate
  );
  const [order, setOrder] = React.useState<Order>("");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("none");
  const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [buyerSelectedOption, setBuyerSelectedOption] = useState();
  const [selectedBuyerData, setSelectedBuyerData] = useState<any>([]);
  const [BuyerCollection, setbuyerCollection] = useState([]);
  const matches = useMediaQuery("(max-width:425px)");

  const [SalesPersonCollection, setSalesPersonCollection] = useState([]);
  const [salesSelectedOption, setSalesSelectedOption] = useState();

  const [selectedSalesData, setSelectedSalesData] = useState<any>([]);

  const openExportType = Boolean(anchorEl2);
  const handleCloseExportType = () => {
    setAnchorEl2(null);
  };

  // const [fromDate, setFromDate] = React.useState<any>();
  // const [toDate, setToDate] = React.useState<any>();
  const handleClickExportType = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl2(event.currentTarget);
  };

  const [resultData, setResultData] = useState<any>(saleList);

  const updateDaterangesOnrenderTime = () => {
    var edMonth = new Date(userContext.financialYearEndDate).getMonth() + 1;
    var edYear = new Date(userContext.financialYearEndDate).getFullYear();
    if (userContext.financialYear !== userContext.currentFinancialYear) {
      setFromDate(new Date(edYear + "-" + edMonth + "-" + "01"));
      setToDate(new Date(userContext.financialYearEndDate));
    } else {
      setFromDate(getStartDate());
      setToDate(getEndDate());
    }
  };

  const [selectedDateRange, setDateRange] = useState([
    {
      startDate: getStartDate(),
      endDate: getEndDate(),
      key: "selection",
    },
  ]);

  const handleChangeCategory = (object: any) => {
    setBuyerSelectedOption(object);
    let tempProductIdData: any = [];
    object.forEach((x) => {
      if (x?.value === "*") {
        return;
      } else {
        let buyerIds = x.buyerId;
        x.buyerId = buyerIds;
        tempProductIdData.push(buyerIds.toString());
      }
    });
    setSelectedBuyerData(tempProductIdData);
  };
  const handleChangeCategoryTwo = (object: any) => {
    setSalesSelectedOption(object);
    let tempStaffIdData: any = [];
    object.forEach((x) => {
      if (x?.value === "*") {
        return;
      } else {
        let staffIds = x.id;
        x.id = staffIds;
        tempStaffIdData.push(staffIds.toString());
      }
    });
    setSelectedSalesData(tempStaffIdData);
  };

  async function fetchSalePersonList() {
    let params = new URLSearchParams();
    params.append("consumerId", userContext.consumerId.toString());
    params.append("firmId", userContext.firmId.toString());
    params.append("branchId", userContext.branchId.toString());
    params.append("pageIndex", page_Index.toString());
    params.append("pageSize", param.pageSize);
    params.append("sortColumn", param.sortColumn);
    params.append("sortDirection", param.sortDirection);
    params.append("searchValue", param.searchValue);
    params.append("lessThanValue", param.lessThanValue);
    params.append("financialYear", userContext.financialYear);
    params.append("customerId", selectedBuyerData);
    params.append("staffIds", selectedSalesData.toString());

    params.append("startDate", format(new Date(fromDate), "yyyy-MM-dd"));
    params.append("endDate", format(new Date(toDate), "yyyy-MM-dd"));

    setLoading(true);
    try {
      const result: any = await GetSaleSummaryBySalePerson(params);
      let endIndex: any;
      if (
        result &&
        result.data.data &&
        result.data.data.saleDetailsListCollection
      ) {
        let saleDetailsListCollection =
          result.data.data.saleDetailsListCollection;
        let dataCount = result.data.data.totalCount;
        let showDataCount = Math.ceil(dataCount / PAGE_SIZE);
        let startIndex = (page_Index - 1) * param.pageSize + 1;
        endIndex =
          (page_Index - 1) * param.pageSize +
          (saleDetailsListCollection ? saleDetailsListCollection.length : 0);
        setResultData(saleDetailsListCollection);
        setCounData(showDataCount);
        setPageStartIndex(startIndex);
        setPageEndIndex(endIndex);
        setTotalCount(dataCount);
      }
    } catch (e) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage:
          typeof e === "string" ? (e as string) : (e?.message as string),
        alertType: AlertType.Error,
      });
    }

    setLoading(false);
  }

  async function openSalesReportPopup(type: any) {
    setAnchorEl2(null);
    setLoading(true);
    let params = new URLSearchParams();
    var attachmentName = "";
    if (type === "pdf") {
      attachmentName =
        `SaleDetailsBySalePerson` +
        format(new Date(fromDate), "_yyyyMMdd") +
        format(new Date(toDate), "_yyyyMMdd") +
        ".pdf";
    } else {
      attachmentName =
        "SaleDetailsBySalePerson" +
        format(new Date(fromDate), "_yyyyMMdd") +
        format(new Date(toDate), "_yyyyMMdd") +
        ".xlsx";
    }
    params.append("reportName", "SaleDetailsBySalePerson");
    params.append("reportType", type);
    params.append("consumerId", userContext.consumerId.toString());
    params.append("branchId", userContext.branchId.toString());
    params.append("firmId", userContext.firmId.toString());
    params.append("financialYear", userContext.financialYear.toString());
    params.append("searchValue", param.searchValue);
    params.append("customerId", selectedBuyerData);
    params.append("mode", "EXPORT");
    params.append("staffIds", selectedSalesData.toString());
    params.append("startDate", format(new Date(fromDate), "yyyy-MM-dd"));
    params.append("endDate", format(new Date(toDate), "yyyy-MM-dd"));
    params.append("fileName", attachmentName);
    params.append("isForDownload", '1');
    try {
      const result: any = await GetSaleSummaryBySalePersonReport(params);
      if (result && result.data && result.data.data) {
        var fileContent = result?.data?.data?.fileData;
        var attachmentData = fileContent;
        ExportFilesFunction(attachmentName, type, attachmentData);
        setLoading(false);
      }
      setLoading(false);
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage:
          typeof e === "string" ? (e as string) : (e?.message as string),
        alertType: AlertType.Error,
      });
    }
    setLoading(false);
  }

  function searchValue(val: any) {
    if (val === "") {
      setValue("");
    }
    setPage_Index(1);
    setParam({ ...param, searchValue: val.toString(), pageIndex: 1 });
  }

  interface EnhancedTableProps {
    order: Order;
    orderBy: String;
    rowCount: number;
  }

  function EnhancedTableHead(props: EnhancedTableProps) {
    return (
      // checkBox for Header
      <TableHead className="table-header-color">
        <TableRow>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              minWidth: "100px",
            }}
            align="left"
          >
            {"Invoice No"}
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              minWidth: "100px",
            }}
            align="center"
          >
            {"Sale Date"}
          </TableCell>

          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              minWidth: "80px",
            }}
            align="left"
          >
            {"Sales Person"}
          </TableCell>

          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              minWidth: "80px",
            }}
            align="right"
          >
            {"Quantity"}
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              minWidth: "80px",
            }}
            align="right"
          >
            {"Free Quantity"}
          </TableCell>

          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              minWidth: "80px",
            }}
            align="right"
          >
            {"Taxable Amount"}
          </TableCell>

          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              minWidth: "80px",
            }}
            align="right"
          >
            {"Tax"}
          </TableCell>

          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              minWidth: "80px",
            }}
            align="right"
          >
            {"Tax Amount"}
          </TableCell>

          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              minWidth: "80px",
            }}
            align="right"
          >
            {"Total Amount"}
          </TableCell>

          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              minWidth: "80px",
            }}
            align="left"
          >
            {"Customer"}
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  const handleChangePageNew = (
    event: React.ChangeEvent<unknown>,
    value: number
    //values: number
  ) => {
    if (Curr_page !== value) {
      setCurrPage(value);
      setPage_Index(value);
      setParam({ ...param, pageIndex: value });
    }
  };

  async function getBuyers(searchValue) {
    let params = new URLSearchParams();
    let buyerCollection: any;
    params.append("consumerId", userContext.consumerId.toString());
    params.append("firmId", userContext.firmId.toString());
    params.append("branchId", userContext.branchId.toString());
    params.append("pageIndex", parametersForSalePage.pageIndex.toString());
    params.append("pageSize", parametersForSalePage.pageSize.toString());
    params.append("sortColumn", parametersForSalePage.sortColumn);
    params.append("sortDirection", parametersForSalePage.sortDirection);
    params.append("searchValue", searchValue);
    params.append("financialYear", userContext.financialYear?.toString());
    // params.append("mode", "DROPDOWN");

    const result: any = await GetBuyerList(params);
    if (result) {
      buyerCollection = result.data.data.buyerCollection;
      setbuyerCollection(buyerCollection);
    }
    return buyerCollection;
  }

  async function getCustomerList() {
    let params = new URLSearchParams();
    let staffCollection: any;

    params.append("consumerId", userContext.consumerId.toString());
    params.append("firmId", userContext.firmId.toString());
    params.append("branchId", userContext.branchId.toString());
    params.append("pageIndex", parametersForSalePage.pageIndex.toString());
    params.append("pageSize", parametersForSalePage.pageSize.toString());
    params.append("sortColumn", parametersForSalePage.sortColumn);
    params.append("sortDirection", parametersForSalePage.sortDirection);

    const result: any = await GetStaffListForInvoice(params);

    if (result && result.data.data) {
      staffCollection = result.data.data.staffListForInvoiceCollection;

      // setSourceStaffCollection(staffCollection);
      setSalesPersonCollection(staffCollection);
    }
    return staffCollection;
  }

  useEffect(() => {
    // GetCurstomerList();
    getBuyers("");
    getCustomerList();
    // getCustomerList("");
    fetchSalePersonList();
  }, [param, fromDate, toDate, selectedBuyerData, selectedSalesData]);

  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          backgroundColor: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isLoading}
      >
        <SplashComponent />
      </Backdrop>
      <Title pageTitle={pageName} />

      {userContext.consumerId > 0 ? (
        <Box
          className="boxContainer"
          sx={{
            "& > :not(style)": {
              width: "100%",
            },
          }}
        >
          <Grid
            className="title-button-responsive"
            item
            xs={6}
            md={3}
            sx={{
              display: "flex !important",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h4 className="page-title">{pageName}</h4>

            <Grid
              item
              xs={6}
              md={3}
              marginRight={0}
              textAlign="end"
              paddingTop="0px"
            >
              <Button
                // color="primary"
                variant="outlined"
                onClick={() => navigate(-1)}
                size="medium"
                startIcon={<KeyboardBackspaceRoundedIcon />}
              >
                Back
              </Button>
            </Grid>
          </Grid>
          <Grid className="paper top-space">
            <Paper elevation={4} className="paper-container">
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={6}>
                  <MultiSelectSim
                    key="buyerId"
                    placeholder="Select Customer.."
                    options={BuyerCollection}
                    onChange={handleChangeCategory}
                    value={buyerSelectedOption}
                    isSelectAll={true}
                    menuPlacement={"bottom"}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <MultiSelectSim
                    key="userId"
                    placeholder="Select Sales Person.."
                    options={SalesPersonCollection}
                    onChange={handleChangeCategoryTwo}
                    value={salesSelectedOption}
                    isSelectAll={true}
                    menuPlacement={"bottom"}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={1}
                sx={{ marginTop: "10px" }}
                className="search-space"
              >
                <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
                  <TextField
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        searchValue(value);
                      }
                    }}
                    sx={{ paddingLeft: "0px !important", width: "100%" }}
                    className="button-responsive-stock"
                    placeholder="Search..."
                    type="text"
                    variant="outlined"
                    size="small"
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => searchValue(value)}>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),

                      endAdornment: value && (
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => searchValue("")}
                        >
                          <ClearIcon />
                        </IconButton>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
                  {/* <TextField
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            lessThanValue(values);
                                        }
                                    }}
                                    sx={{ width: '100%' }}
                                    className="button-responsive-stock"
                                    placeholder="Stock is less than..."
                                    type="number"
                                    variant="outlined"
                                    size="small"
                                    onChange={(e) => setValues(e.target.value)}
                                    value={values}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => lessThanValue(values)}>
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),

                                        endAdornment: values && (
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => lessThanValue("")}
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        ),
                                    }}
                                /> */}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                  <Grid
                    sx={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                      gap: "20px",
                      flexDirection: matches && "column",
                    }}
                  >
                    <CustomDateRangePicker
                      fromDate={fromDate}
                      setFromDate={setFromDate}
                      toDate={toDate}
                      setToDate={setToDate}
                    />
                    <Button
                      className="button-responsive-stock"
                      aria-controls={
                        openExportType ? "demo-customized-menu" : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={openExportType ? "true" : undefined}
                      disabled={resultData?.length <= 0 ? true : false}
                      variant="outlined"
                      disableElevation
                      onClick={handleClickExportType}
                      startIcon={<DownloadIcon />}
                      endIcon={<KeyboardArrowDownSharp />}
                    >
                      Export
                    </Button>
                    <StyledMenu
                      id="demo-customized-menu"
                      MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                      }}
                      anchorEl={anchorEl2}
                      open={openExportType}
                      onClose={handleCloseExportType}
                    >
                      <MenuItem
                        disableRipple
                        onClick={() => {
                          openSalesReportPopup("pdf");
                        }}
                      >
                        PDF
                      </MenuItem>
                      <MenuItem
                        disableRipple
                        onClick={() => {
                          openSalesReportPopup("Excel");
                        }}
                      >
                        EXCEL
                      </MenuItem>
                    </StyledMenu>
                  </Grid>
                </Grid>
              </Grid>

              <TableContainer>
                <Table
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                >
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    rowCount={resultData?.length}
                  />
                  {resultData?.length > 0 && (
                    <TableBody>
                      {stableSort(resultData, getComparator(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item: any, index: any) => {
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={index + 1}
                            >
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                align="left"
                                sx={{
                                  display: {
                                    xs: "table-cell",
                                    sm: "table-cell",
                                    md: "table-cell",
                                  },
                                }}
                              >
                                <span>{item.saleNumber}</span>
                              </TableCell>

                              <TableCell
                                sx={{
                                  display: {
                                    xs: "table-cell",
                                    sm: "table-cell",
                                    md: "table-cell",
                                  },
                                }}
                                align="center"
                              >
                                <span>{item?.saleDate}</span>
                              </TableCell>
                              <TableCell
                                sx={{
                                  display: {
                                    xs: "table-cell",
                                    sm: "table-cell",
                                    md: "table-cell",
                                  },
                                }}
                                align="left"
                              >
                                {item?.fullName}
                              </TableCell>

                              <TableCell
                                sx={{
                                  display: {
                                    xs: "table-cell",
                                    sm: "table-cell",
                                    md: "table-cell",
                                  },
                                }}
                                align="right"
                              >
                                {numberFormat(item?.quantity)}
                              </TableCell>

                              <TableCell
                                sx={{
                                  display: {
                                    xs: "table-cell",
                                    sm: "table-cell",
                                    md: "table-cell",
                                  },
                                }}
                                align="right"
                              >
                                {numberFormat(item?.freeQuantity)}
                              </TableCell>

                              <TableCell
                                sx={{
                                  display: {
                                    xs: "table-cell",
                                    sm: "table-cell",
                                    md: "table-cell",
                                  },
                                }}
                                align="right"
                              >
                                {numberFormat(item.taxableAmount)}
                              </TableCell>

                              <TableCell
                                sx={{
                                  display: {
                                    xs: "table-cell",
                                    sm: "table-cell",
                                    md: "table-cell",
                                  },
                                }}
                                align="right"
                              >
                                {item.integratedTaxPercentage + "%"}
                              </TableCell>

                              <TableCell
                                sx={{
                                  display: {
                                    xs: "table-cell",
                                    sm: "table-cell",
                                    md: "table-cell",
                                  },
                                }}
                                align="right"
                              >
                                {numberFormat(item.integratedTaxAmount)}
                              </TableCell>

                              <TableCell
                                sx={{
                                  display: {
                                    xs: "table-cell",
                                    sm: "table-cell",
                                    md: "table-cell",
                                  },
                                }}
                                align="right"
                              >
                                {numberFormat(item.totalAmount)}
                              </TableCell>
                              <TableCell
                                sx={{
                                  display: {
                                    xs: "table-cell",
                                    sm: "table-cell",
                                    md: "table-cell",
                                  },
                                }}
                                align="left"
                              >
                                {item.customer}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {resultData?.length > 0 && (
                        <TableRow hover tabIndex={-1} key={"0"}>
                          <TableCell className="bold-600">Total</TableCell>

                          <TableCell
                            align="right"
                            className="bold-600 "
                            sx={{
                              display: {
                                xs: "table-cell",
                                sm: "table-cell",
                                md: "table-cell",
                                lg: "table-cell",
                              },
                            }}
                          >
                            {""}
                          </TableCell>

                          <TableCell
                            align="right"
                            className="bold-600 "
                            sx={{
                              display: {
                                xs: "table-cell",
                                sm: "table-cell",
                                md: "table-cell",
                                lg: "table-cell",
                              },
                            }}
                          >
                            {""}
                          </TableCell>

                          <TableCell
                            align="right"
                            className="bold-600 "
                            sx={{
                              display: {
                                xs: "table-cell",
                                sm: "table-cell",
                                md: "table-cell",
                                lg: "table-cell",
                              },
                            }}
                          >
                            {numberFormat(
                              resultData?.reduce(
                                (acc, curr) =>
                                  acc + (curr?.quantity ? curr?.quantity : 0),
                                0
                              )
                            )}
                          </TableCell>
                          <TableCell
                            align="right"
                            className="bold-600 "
                            sx={{
                              display: {
                                xs: "table-cell",
                                sm: "table-cell",
                                md: "table-cell",
                                lg: "table-cell",
                              },
                            }}
                          >
                            {numberFormat(
                              resultData?.reduce(
                                (acc, curr) =>
                                  acc +
                                  (curr?.freeQuantity ? curr?.freeQuantity : 0),
                                0
                              )
                            )}
                          </TableCell>

                          <TableCell
                            align="right"
                            className="bold-600 "
                            sx={{
                              display: {
                                xs: "table-cell",
                                sm: "table-cell",
                                md: "table-cell",
                                lg: "table-cell",
                              },
                            }}
                          >
                            {numberFormat(
                              resultData?.reduce(
                                (acc, curr) =>
                                  acc +
                                  (curr?.taxableAmount
                                    ? curr?.taxableAmount
                                    : 0),
                                0
                              )
                            )}
                          </TableCell>
                          <TableCell
                            align="right"
                            className="bold-600 "
                            sx={{
                              display: {
                                xs: "table-cell",
                                sm: "table-cell",
                                md: "table-cell",
                                lg: "table-cell",
                              },
                            }}
                          >
                            {""}
                          </TableCell>

                          <TableCell align="right" className="bold-600 ">
                            {numberFormat(
                              resultData?.reduce(
                                (acc, curr) =>
                                  acc +
                                  (curr?.integratedTaxAmount
                                    ? curr?.integratedTaxAmount
                                    : 0),
                                0
                              )
                            )}
                          </TableCell>

                          <TableCell
                            align="right"
                            className="bold-600 "
                            sx={{
                              display: {
                                xs: "table-cell",
                                sm: "table-cell",
                                md: "table-cell",
                                lg: "table-cell",
                              },
                            }}
                          >
                            {numberFormat(
                              resultData?.reduce(
                                (acc, curr) =>
                                  acc +
                                  (curr?.totalAmount ? curr?.totalAmount : 0),
                                0
                              )
                            )}
                          </TableCell>

                          <TableCell
                            align="right"
                            className="bold-600 "
                            sx={{
                              display: {
                                xs: "table-cell",
                                sm: "table-cell",
                                md: "table-cell",
                                lg: "table-cell",
                              },
                            }}
                          >
                            {}
                          </TableCell>
                        </TableRow>
                      )}
                      {resultData?.length <= 0 && (
                        <TableRow hover tabIndex={-1} key={"0"}>
                          <TableCell colSpan={6} align="center">
                            No record(s) found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  )}
                </Table>
                {resultData?.length > 0 && (
                  <Grid
                    container
                    paddingTop={1}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Grid item xs={12} sm={6} className="pagination-label">
                      {pageStartIndex} - {pageEndIndex} of {totalCount}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack
                        component="div"
                        alignItems={mobileScreen ? " center" : "end"}
                        justifyContent={"center"}
                      >
                        <Pagination
                          color="primary"
                          count={counData}
                          page={page_Index}
                          onChange={handleChangePageNew}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                )}
              </TableContainer>
            </Paper>
          </Grid>
        </Box>
      ) : (
        <Box
          className="boxContainer"
          sx={{
            "& > :not(style)": {
              width: "100%",
            },
          }}
        >
          <MissingConsumer consumerId={userContext.consumerId} />
        </Box>
      )}
    </>
  );
};

export default SaleSummaryBySalesPerson;
