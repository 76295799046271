import { get, post } from "./common/http";
import { BASE_URL } from "./common/const";

export const GetUserGroupDetail = (params: any) =>
  get(`${BASE_URL}/api/UserGroup/GetDetail?` + params, null);

export const GetUserGroupList = (params: any) =>
  get(`${BASE_URL}/api/UserGroup/GetList?` + params, null);

export const GetUserGroupListForSelect = (params: any) =>
  get(`${BASE_URL}/api/UserGroup/GetListForSelect?` + params, null);

export const CreateUserGroup = (data: any) =>
  post(`${BASE_URL}/api/UserGroup/Create`, data);

export const UpdateUserGroup = (data: any) =>
  post(`${BASE_URL}/api/UserGroup/Update`, data);

export const DeleteUserGroup = (data: any) =>
  post(`${BASE_URL}/api/UserGroup/Delete`, data);

export const GetUserGroupMemberList = (params: any) =>
  get(`${BASE_URL}/api/UserGroup/GetUserGroupMemberList?` + params, null);

export const TransferMembersToAnotherUserGroup = (data: any) =>
  post(`${BASE_URL}/api/UserGroup/TransferMembers`, data);

