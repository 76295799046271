// import React, { useState, useEffect } from "react";
// import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

// export type Option = {
//   value: number | string;
//   label: string;
// };
// interface MultiSelectAllProps {
//   options: Option[];
//   onChange: (selectedOptions: Option[]) => void;
//   placeholderLabel?: string;
//   defaultOptions?: any;
// }
// const MultiSelect: React.FC<MultiSelectAllProps> = ({ options, onChange, placeholderLabel, defaultOptions }) => {
//   const [selectedOptions, setSelectedOptions] = useState<any>([]);
//   const [forDefaultoption, setForDefaultoption] = useState<any>([defaultOptions]);

//   useEffect(() => {
//     setSelectedOptions([{ label: "All", value: "*" }, ...options]);
//   }, [options]);

//   function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
//     if (value && value.some((o) => o.value === "*")) {
//       return `${placeholderButtonLabel}: All`;
//     } else if (value.length === 1) {
//       return (`${placeholderButtonLabel}: ` + `${value[0]?.label}`);
//     }
//     else {
//       return `${placeholderButtonLabel}: ${value.length} selected`;
//     }
//   }

//   function handleChange(value, event) {
//     let updatedOptions: any = [];
//     if (event?.action === "select-option" && event?.option?.value === "*") {
//       setSelectedOptions(this?.options);
//       updatedOptions = this?.options;
//     } else if (
//       event?.action === "deselect-option" &&
//       event?.option?.value === "*"
//     ) {
//       setSelectedOptions([]);
//       updatedOptions = [];
//     } else if (event?.action === "deselect-option") {
//       const deselect = value.filter((o) => o.value !== "*");
//       setSelectedOptions(deselect);
//       updatedOptions = deselect;
//     } else if (value.length === this?.options?.length - 1) {
//       setSelectedOptions(this?.options);
//       updatedOptions = this?.options;
//     } else {
//       setSelectedOptions(value);
//       updatedOptions = value;
//     }
//     onChange(updatedOptions)
//   }

//   return (
//     <ReactMultiSelectCheckboxes
//       options={[{ label: "All", value: "*" }, ...options]}
//       placeholderButtonLabel={placeholderLabel}
//       getDropdownButtonLabel={getDropdownButtonLabel}
//       value={selectedOptions}
//       onChange={handleChange}
//       setState={setSelectedOptions}
//     />
//   );
// };

// export default MultiSelect;

import { Button, Menu } from "@mui/material";
import { useState, useRef, ReactNode } from "react";
import Select, {
  default as ReactSelect,
  components,
  InputAction,
  StylesConfig,
} from "react-select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React from "react";

export type Option = {
  value: number | string;
  label: string;
};

const MultiSelect = (props: any) => {
  const [selectInput, setSelectInput] = useState<string>("");
  const isAllSelected = useRef<boolean>(false);
  const selectAllLabel = useRef<string>("Select all");
  const buttonLabelRef = useRef<string>("");
  const allOption = { value: "*", label: selectAllLabel.current };
  let buttonLabel = buttonLabelRef.current;
  const [isOpen, setIsOpen] = useState(false);
  const filterOptions = (options: Option[], input: string) =>
    options?.filter(({ label }: Option) =>
      label.toLowerCase().includes(input.toLowerCase())
    );

  const comparator = (v1: Option, v2: Option) =>
    (v1.value as number) - (v2.value as number);

  let filteredOptions = filterOptions(props.options, selectInput);
  let filteredSelectedOptions = filterOptions(props.value, selectInput);

  const Option = (props: any) => (
    <components.Option {...props}>
      {props.value === "*" &&
      !isAllSelected.current &&
      filteredSelectedOptions?.length > 0 ? (
        <input
          key={props.value}
          type="checkbox"
          ref={(input) => {
            if (input) input.indeterminate = true;
          }}
        />
      ) : (
        <input
          key={props.value}
          type="checkbox"
          checked={props.isSelected || isAllSelected.current}
          onChange={() => {}}
        />
      )}
      <label style={{ marginLeft: "5px" }}>{props.label}</label>
    </components.Option>
  );

  const customFilterOption = ({ value, label }: Option, input: string) =>
    (value !== "*" && label.toLowerCase().includes(input.toLowerCase())) ||
    (value === "*" && filteredOptions?.length > 0);

  const onInputChange = (
    inputValue: string,
    event: { action: InputAction }
  ) => {
    if (event.action === "input-change") setSelectInput(inputValue);
    else if (event.action === "menu-close" && selectInput !== "")
      setSelectInput("");
  };

  const handleChange = (selected: Option[]) => {
    if (selected?.length === props.options?.length + 1) {
      setSelectInput("");
      return props.onChange([
        ...props.value?.filter(
          ({ label }: Option) =>
            !label.toLowerCase().includes(selectInput?.toLowerCase())
        ),
      ]);
    }

    if (
      selected.length > 0 &&
      !isAllSelected.current &&
      (selected[selected.length - 1].value === allOption.value ||
        JSON.stringify(filteredOptions) ===
          JSON.stringify(selected.sort(comparator)))
    )
      return props.onChange(
        [
          ...(props.value ?? []),
          ...props.options.filter(
            ({ label }: Option) =>
              label.toLowerCase().includes(selectInput?.toLowerCase()) &&
              (props.value ?? []).filter((opt: Option) => opt.label === label)
                .length === 0
          ),
        ].sort(comparator)
      );
    else if (
      selected.length > 0 &&
      selected[selected.length - 1].value !== allOption.value &&
      JSON.stringify(selected.sort(comparator)) !==
        JSON.stringify(filteredOptions)
    )
      return props.onChange(selected);
    else
      return props.onChange([
        ...props.value?.filter(
          ({ label }: Option) =>
            !label.toLowerCase().includes(selectInput?.toLowerCase())
        ),
      ]);
  };
  if (props.isSelectAll && props.options.length !== 0) {
    isAllSelected.current =
      JSON.stringify(filteredSelectedOptions) ===
      JSON.stringify(filteredOptions);
    if (props?.value !== null && props?.value?.length === 1) {
      buttonLabelRef.current = props?.value[0]?.label;
    }
    if (filteredSelectedOptions?.length > 0) {
      if (filteredSelectedOptions?.length === filteredOptions?.length) {
        selectAllLabel.current = `All (${filteredOptions.length}) selected`;
        if (props?.value !== null && props?.value?.length !== 1)
          buttonLabelRef.current = `All (${filteredOptions.length}) selected`;
      } else {
        selectAllLabel.current = `${filteredSelectedOptions?.length} / ${filteredOptions.length} selected`;
        if (props?.value !== null && props?.value?.length !== 1)
          buttonLabelRef.current = `${filteredSelectedOptions?.length} / ${filteredOptions.length} selected`;
      }
    } else {
      selectAllLabel.current = "Select all";
      buttonLabelRef.current = "";
    }

    allOption.label = selectAllLabel.current;
    buttonLabel = buttonLabelRef.current;
  }

  const selectStyles: StylesConfig<Option, false> = {
    control: (provided) => ({
      ...provided,
      minWidth: 250,
      margin: 8,
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    // menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
  };
  const Menu = (props: JSX.IntrinsicElements["div"]) => {
    const shadow = "hsla(218, 50%, 10%, 0.1)";
    return (
      <div
        style={{
          backgroundColor: "primary",
          borderRadius: 4,
          boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
          marginTop: 8,
          position: "absolute",
          zIndex: 2,
        }}
        {...props}
      />
    );
  };

  const Dropdown = ({
    children,
    isOpen,
    target,
    onClose,
  }: {
    children?: ReactNode;
    readonly isOpen: boolean;
    readonly target: ReactNode;
    readonly onClose: () => void;
  }) => (
    <div style={{ position: "relative" }}>
      {target}
      {isOpen ? <Menu>{children}</Menu> : null}
    </div>
  );

  return (
    <Dropdown
      isOpen={isOpen}
      onClose={() => {
        return setIsOpen(false);
      }}
      target={
        <Button
          endIcon={<KeyboardArrowDownIcon />}
          sx={{
            cursor: "pointer",
            display: "inline-flex",
            alignItems: "center",
            maxWidth: "100%", // Ensure the button does not exceed the container width
            // textTransform: "none",
          }}
          className={
            filteredSelectedOptions?.length > 0 ? "link-blue-color" : ""
          }
          onClick={() => setIsOpen((prev) => !prev)}
        >
          {props.placeholderLabel}:{" "}
          <span
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "calc(100% - 20px)", // Adjust width based on icon and spacing
              display: "inline-block",
              verticalAlign: "middle",
              marginLeft:'5px'
            }}
          >
            {buttonLabel}
          </span>
        </Button>
      }
    >
      <Select
        {...props}
        isSelectAll={props.isSelectAll}
        autoFocus
        inputValue={selectInput}
        onInputChange={onInputChange}
        isMulti
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "#0b0b45", //active
            primary25: "#f1f6fe", //hover
            primary50: "#0b0b45", //clicable
          },
        })}
        menuPlacement={"auto"}
        filterOption={customFilterOption}
        backspaceRemovesValue={false}
        components={{ Option: Option }}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        isClearable={false}
        menuIsOpen
        onChange={(e: any) => {
          handleChange(e);
        }}
        onBlur={(e) => {
          setIsOpen(false);
        }}
        options={[allOption, ...props.options]}
        placeholder="Search..."
        styles={selectStyles}
        tabSelectsValue={false}
        menuPortalTarget={document.body}
      />
    </Dropdown>
  );
};

export default MultiSelect;
