import { get, post } from "./common/http";
import { BASE_URL } from "./common/const";

export const GetMasterGroupTypeList = () =>
    get(`${BASE_URL}/api/Account/GetMasterGroupListForSelect`, null);

export const GetGroupTypeList = (params: any) =>
    get(`${BASE_URL}/api/Account/GetListForSelect?` + params, null);

export const GetAccountList = (params: any) =>
    get(`${BASE_URL}/api/Account/GetList?` + params, null);
export const RecalulateLedgerSummary = (params: any) =>
    get(`${BASE_URL}/api/Account/GetRecalulateLedgerSummary?` + params, null);

export const GetAccountDetail = (params: any) =>
    get(`${BASE_URL}/api/Account/GetDetail?` + params, null);

export const CreateAccount = (data: any) =>
    post(`${BASE_URL}/api/Account/Create`, data);

export const UpdateAccount = (data: any) =>
    post(`${BASE_URL}/api/Account/Update`, data);

export const DeleteAccount = (data: any) =>
    post(`${BASE_URL}/api/Account/Delete`, data);

export const GetAccountsListForExpense = (params: any) =>
    get(`${BASE_URL}/api/Account/GetAccountsListForExpense?` + params, null);

export const GetAccountsForExpenseDynamicField = (params: any) =>
    get(`${BASE_URL}/api/Account/GetAccountsForExpenseDynamicField?` + params, null);


export const GetAccountsForJournalVoucherDynamicField = (params: any) =>
    get(`${BASE_URL}/api/Account/GetAccountsForJournalEnteriesDynamicField?` + params, null);

export const GetMasterAccountList = () =>
    get(`${BASE_URL}/api/Account/GetMasterAccountList?`, null);
export const GetMasterLedgerGroupList = () =>
    get(`${BASE_URL}/api/Account/GetMasterLedgerGroupList?`, null);


export const GetAccountDetailByPerameters = (params: any) =>
    get(`${BASE_URL}/api/Account/GetAccountDetailByPerameters?` + params, null);

export const GetAccountsForSalesReport = (params: any) =>
    get(`${BASE_URL}/api/Account/GetAccountsForSalesReceipt?` + params, null);

export const GetLedgerAccountSummary = (params: any) =>
    get(`${BASE_URL}/api/Account/GetLedgerAccountSummary?` + params, null);

export const GetLedgerListByAccountCode = (params: any) =>
    get(`${BASE_URL}/api/Account/GetListByAccountCode?` + params, null);




