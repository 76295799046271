import React, { useContext } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  TextField,
  Grid,
  MenuItem,
  InputAdornment,
  Pagination,
  Stack,
  Backdrop,
  useTheme,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import DownloadIcon from "@mui/icons-material/Download";
import { useLocation, useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { PAGE_SIZE } from "../../Services/common/const";
import Title from "../../Components/Title";
import { PreferencesContext } from "../../PreferencesContext";
import MissingConsumer from "../../Components/MissingConsumer";
import {
  ExportFilesFunction,
  numberFormat,
  numberFormatWithCrDr,
} from "../../shared/CommonFuctions";
import { KeyboardArrowDownSharp, Label } from "@mui/icons-material";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import SplashComponent from "../../Navigation/splash";
import { AlertType } from "../../shared/Modal/AlertType";
import { EncryptDecryptService } from "../../Services/common/EncryptionService";
import { StyledMenu } from "../../Components/StyleMenus";
import { format } from "date-fns";
import CustomDateRangePicker from "../../Components/CustomDateRange";
import { getEndDate, getStartDate } from "../../shared/CommonFuctions";
import {
  GetLedgerGroupSummaryAdvance,
  GetOutstandingReceivableSummaryReport,
  GetSaleSummaryByCustomerReport,
} from "../../Services/ReportService";
import { GetOutstandingReceivableSummaryList } from "../../Services/Register";
import MultiSelect from "../../Components/MultiSelect";
import { GetBuyerList } from "../../Services/BuyerService";
import Select from "react-select";
import {
  GetLedgerAccountSummary,
  GetLedgerListByAccountCode,
  GetMasterLedgerGroupList,
} from "../../Services/AccountService";

interface IBuyer {
  buyerId: number;
  consumerId: number;
  firmId: number;
  organizationName: string;
  firstName: string;
  lastName: string;
  fullName: string;
  address1: string;
  address2: string;
  city: string;
  code: string;
  contactNumber: string;
  state: number;
  stateName: string;
  stateCode: string;
  country: number;
  email: string;
  taxNumber: string;
  type: number;
  label: string;
  value: string;
}

const parameters: any = {
  consumerId: 25,
  branchId: 1,
  pageIndex: 1,
  pageSize: PAGE_SIZE,
  sortColumn: "",
  sortDirection: "",
  searchValue: "",
  lessThanValue: "",
};
type Order = "asc" | "desc" | "";

interface Data {
  saleNumber: string;
  staff_code: string;
  sale_Id: Number;
  fullName: string;
}
const saleList: Data = {
  saleNumber: "",
  staff_code: "",
  sale_Id: 0,
  fullName: "",
};
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const LedgerGroupSummaryAdvance = () => {
  const theme = useTheme();
  let navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(PAGE_SIZE);
  const [value, setValue] = useState("");
  const { userContext, updateUserContext } =
    React.useContext(PreferencesContext);
  const [isLoading, setLoading] = React.useState(false);
  const pageName = "Ledger Group Summary";
  const [dense, setDense] = React.useState(false);
  const [page_Index, setPage_Index] = useState(1);
  const [counData, setCounData] = useState(0);
  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
  const [Curr_page, setCurrPage] = React.useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [pageStartIndex, setPageStartIndex] = useState(1);
  const [pageEndIndex, setPageEndIndex] = useState(1);
  const [param, setParam] = useState(parameters);
  // const [toDate, setToDate] = React.useState<any>(getEndDateOnInit(userContext));
  // const [fromDate, setFromDate] = React.useState<any>(getStartDateOnInit(userContext));
  const [fromDate, setFromDate] = React.useState<any>(
    userContext.financialYearStartDate
  );
  const [totalAmount, setTotalAmount] = useState({
    closingBalance: 0,
    debit: 0,
    credit: 0,
    // kasar: 0,
  });

  const [toDate, setToDate] = React.useState<any>(
    userContext.financialYearEndDate
  );
  const [nature, setNature] = useState("");
  const [reportType, setReportType] = useState("");

  const natures = [
    { label: "Cr", value: "CR" },
    { label: "Dr", value: "DR" },
  ];

  const reportTypes = [
    { label: "Payments Only", value: "PAYMENT_ONLY" },
    { label: "Bank Only", value: "BANK_ONLY" },
    { label: "Cash Only", value: "CASH_ONLY" },
  ];

  const [order, setOrder] = React.useState<Order>("");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("none");
  const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [setselectedBuyerData, setSetselectedBuyerData] = useState<any>([]);
  const matches = useMediaQuery("(max-width:710px)");
  const edService = new EncryptDecryptService();
  const openExportType = Boolean(anchorEl2);
  const handleCloseExportType = () => {
    setAnchorEl2(null);
  };
  const [sourceGroupColl, setsourceGroupColl] = React.useState([]);
  const [selectedGroupCodesData, setselectedGroupCodesData] =
    React.useState<any>("");
  const [selectedGroupCodes, setSelectedGroupCodes] = React.useState<any>([]);
  const [selectedLedgerAccount, setSelectedLedgerAccount] = React.useState<any>([]);
  const [selectedLedgerAccountData, setSelectedLedgerAccountData] = React.useState<any>('');
  const [sourceLedgerColl, setSourceLedgerColl] = React.useState<any>([]);
  // const [fromDate, setFromDate] = React.useState<any>();
  // const [toDate, setToDate] = React.useState<any>();
  const handleClickExportType = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl2(event.currentTarget);
  };
  const [resultData, setResultData] = useState<any>(saleList);

  async function handleMoveToSale(saleId: any) {
    try {
      let encSaletId: any = edService.encrypt(saleId);
      navigate(
        {
          pathname: "/sale-by-customer",
          search: `?q=${encodeURIComponent(encSaletId)}`,
        },
        {
          // state:  { title: ""}
        }
      );
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage:
          typeof e === "string" ? (e as string) : (e?.message as string),
        alertType: AlertType.Error,
      });
    }
  }
  async function handleMoveToOutstandingReceivable(buyerId: any) {
    try {
      let encBuyerId: any = edService.encrypt(buyerId);
      navigate(
        {
          pathname: "/ledger-voucher",
          search: `?q=${encodeURIComponent(encBuyerId)}`,
        },
        {
          // state:  { title: ""} 
        }
      );
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage:
          typeof e === "string" ? (e as string) : (e?.message as string),
        alertType: AlertType.Error,
      });
    }
  }

  const handleChange = (object: any) => {
    setSelectedGroupCodes(object);
    setselectedGroupCodesData(object?.map((x) => x.value)?.join(","));
  };
  
  const handleChangeAccount = (object: any) => {
    setSelectedLedgerAccount(object);
    setSelectedLedgerAccountData(object?.map((x) => x.value)?.join(","));
  };

  async function fetchData() {
    let params = new URLSearchParams();
    params.append("consumerId", userContext.consumerId.toString());
    params.append("firmId", userContext.firmId.toString());
    params.append("branchId", userContext.branchId.toString());
    params.append("financialYear", userContext.financialYear.toString());
    params.append("startDate", format(new Date(fromDate), "yyyy-MM-dd"));
    params.append("endDate", format(new Date(toDate), "yyyy-MM-dd"));
    params.append("pageIndex", page_Index.toString());
    params.append("pageSize", param.pageSize);
    params.append("accountCodes", selectedGroupCodesData);
    params.append("accountIds", selectedLedgerAccountData);
    params.append("mode", "");
    setLoading(true);
    try {
      const result: any = await GetLedgerAccountSummary(params);
      let endIndex: any;
      if (result && result.data.data && result.data.data.accountCollection) {
        let resultData = result?.data?.data;
        let accountCollection = result.data.data.accountCollection;
        let dataCount = result.data.data.totalCount;
        let showDataCount = Math.ceil(dataCount / PAGE_SIZE);
        let startIndex = (page_Index - 1) * param.pageSize + 1;
        endIndex =
          (page_Index - 1) * param.pageSize +
          (accountCollection ? accountCollection.length : 0);
        setTotalAmount({
          closingBalance: resultData?.closingBalanceTotal || 0,
          credit: resultData?.creditTotal || 0,
          debit: resultData?.debitTotal || 0,
        });

        setResultData(accountCollection);
        setCounData(showDataCount);
        setPageStartIndex(startIndex);
        setPageEndIndex(endIndex);
        setTotalCount(dataCount);
      }
    } catch (e) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage:
          typeof e === "string" ? (e as string) : (e?.message as string),
        alertType: AlertType.Error,
      });
    }

    setLoading(false);
  }

  async function openOutstandingReceivableSummaryPopup(type: any) {
    setAnchorEl2(null);
    setLoading(true);
    let params = new URLSearchParams();
    var attachmentName = "";
        if (type === "pdf") {
          attachmentName =
            `SaleSummaryByCustomerReport` +
            format(new Date(fromDate), "_yyyyMMdd") +
            format(new Date(toDate), "_yyyyMMdd") +
            ".pdf";
        } else {
          attachmentName =
            "SaleSummaryByCustomerReport" +
            format(new Date(fromDate), "_yyyyMMdd") +
            format(new Date(toDate), "_yyyyMMdd") +
            ".xls";
        }
    params.append("reportName", "LedgerGroupSummaryAdvanceReport");
    params.append("reportType", type);
    params.append("consumerId", userContext.consumerId.toString());
    params.append("branchId", userContext.branchId.toString());
    params.append("firmId", userContext.firmId.toString());
    if (selectedGroupCodesData !== null) {
      params.append("buyerIds", selectedGroupCodesData);
    }
    params.append("financialYear", userContext.financialYear.toString());
    params.append("searchValue", param.searchValue);
    params.append("mode", "EXPORT");
    params.append("nature", nature);
    params.append("type", reportType);
    params.append("startDate", format(new Date(fromDate), "yyyy-MM-dd"));
    params.append("endDate", format(new Date(toDate), "yyyy-MM-dd"));
    params.append("accountCodes", selectedGroupCodesData)
    params.append("ledgerAccountIds", selectedLedgerAccountData)
    params.append("fileName", attachmentName);
    params.append("isForDownload", '1');
    try {
      const result: any = await GetLedgerGroupSummaryAdvance(params);
      if (result && result.data && result.data.data) {
        var fileContent = result?.data?.data?.fileData;
        var attachmentData = fileContent;
        
        ExportFilesFunction(attachmentName, type, attachmentData);
        setLoading(false);
      }
      setLoading(false);
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage:
          typeof e === "string" ? (e as string) : (e?.message as string),
        alertType: AlertType.Error,
      });
    }
    setLoading(false);
  }

  function searchValue(val: any) {
    if (val === "") {
      setValue("");
    }
    setPage_Index(1);
    setParam({ ...param, searchValue: val.toString(), pageIndex: 1 });
  }

  interface EnhancedTableProps {
    order: Order;
    orderBy: String;
    rowCount: number;
  }

  const handleChangePageNew = (
    event: React.ChangeEvent<unknown>,
    value: number
    //values: number
  ) => {
    if (Curr_page !== value) {
      setCurrPage(value);
      setPage_Index(value);
      setParam({ ...param, pageIndex: value });
    }
  };

  async function fetchAccountGroupType() {
    try {
      const groupTypeData: any = await GetMasterLedgerGroupList();
      if (groupTypeData?.data?.data) {
        let groupTypeCollection = groupTypeData.data.data;
        setsourceGroupColl(
          groupTypeCollection?.map((x) => {
            return { label: x.label, value: x.accountCode };
          })
        );
      }
    } catch (e: any) {
      // setSubmitting(false);
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage:
          typeof e === "string" ? (e as string) : (e?.message as string),
        alertType: AlertType.Error,
      });
    }
  }

  async function fetchLedgerAccountListByCode(codes) {
    try {
      let params = new URLSearchParams();
      params.append("consumerId", userContext.consumerId.toString());
      params.append("firmId", userContext.firmId.toString());
      params.append("branchId", userContext.branchId.toString());
      params.append("accountCodes", codes);
      const ledgerData: any = await GetLedgerListByAccountCode(params);
      if (ledgerData?.data?.data) {
        let ledgerList = ledgerData.data.data;
        setSourceLedgerColl(ledgerList);
      }
    } catch (e: any) {
      // setSubmitting(false);
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage:
          typeof e === "string" ? (e as string) : (e?.message as string),
        alertType: AlertType.Error,
      });
    }
  }

  useEffect(() => {
    fetchData();
  }, [param, selectedGroupCodesData, selectedLedgerAccountData, fromDate, toDate]);

  useEffect(() => {
    fetchAccountGroupType();
  }, []);

  useEffect(() => {
    fetchLedgerAccountListByCode(selectedGroupCodesData);
  }, [selectedGroupCodesData]);

  function EnhancedTableHead(props: EnhancedTableProps) {
    return (
      // checkBox for Header
      <TableHead className="table-header-color">
        <TableRow>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              minWidth: "80px",
            }}
            align="left"
          >
            {"Account Name"}
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              minWidth: "80px",
            }}
            align="right"
          >
            {"Debit ₹"}
          </TableCell>

          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              minWidth: "80px",
            }}
            align="right"
          >
            {"Credit ₹"}
          </TableCell>

          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              minWidth: "80px",
            }}
            align="right"
          >
            {"Closing Balance ₹"}
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          backgroundColor: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isLoading}
      >
        <SplashComponent />
      </Backdrop>
      <Title pageTitle={pageName} />

      {userContext.consumerId > 0 ? (
        <Box
          className="boxContainer"
          sx={{
            "& > :not(style)": {
              width: "100%",
            },
          }}
        >
          <Grid
            className="title-button-responsive"
            item
            xs={6}
            md={3}
            sx={{
              display: "flex !important",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h4 className="page-title">{pageName}</h4>
            {!matches && (
              <>
                <Grid
                  item
                  xs={6}
                  md={3}
                  marginRight={0}
                  textAlign="end"
                  paddingTop="0px"
                >
                  <Box sx={{ display: "flex", gap: 2, alignItems: "end" }}>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography>Debit ₹</Typography>
                      <Box className={"inner-tabs tab-all"}>
                        ₹{numberFormat(totalAmount?.debit)}
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography>Credit ₹</Typography>
                      <Box className={"inner-tabs tab-all"}>
                        ₹{numberFormat(totalAmount?.credit)}
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography>Closing Balance ₹</Typography>
                      <Box className={"inner-tabs tab-all"}>
                        ₹{numberFormat(totalAmount?.closingBalance)}
                      </Box>
                    </Box>
                    <Box>
                      <Button
                        // color="primary"
                        variant="outlined"
                        onClick={() => navigate(-1)}
                        size="medium"
                        startIcon={<KeyboardBackspaceRoundedIcon />}
                      >
                        Back
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
          {matches && (
            <>
              <Grid item xs={12} md={3}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography>Debit ₹</Typography>
                    <Box className={"inner-tabs tab-all"}>
                      ₹{numberFormat(totalAmount?.debit)}
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography>Credit ₹</Typography>
                    <Box className={"inner-tabs tab-all"}>
                      ₹{numberFormat(totalAmount?.credit)}
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography>Closing Balance ₹</Typography>
                    <Box className={"inner-tabs tab-all"}>
                      ₹{numberFormatWithCrDr(totalAmount?.closingBalance, "DR")}
                    </Box>
                  </Box>
                  <Box>
                    <Button
                      // color="primary"
                      variant="outlined"
                      onClick={() => navigate(-1)}
                      size="medium"
                      startIcon={<KeyboardBackspaceRoundedIcon />}
                    >
                      Back
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </>
          )}
          <Grid className="paper top-space">
            <Paper elevation={4} className="paper-container">
              <Grid
                container
                spacing={1}
                sx={{ marginTop: "10px" }}
                className="search-space"
              >
                <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
                  <MultiSelect
                    placeholderLabel="Group"
                    options={sourceGroupColl}
                    onChange={handleChange}
                    value={selectedGroupCodes}
                    isSelectAll={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={4} xl={4}>
                  <MultiSelect
                    placeholderLabel="Account"
                    options={sourceLedgerColl}
                    onChange={handleChangeAccount}
                    value={selectedLedgerAccount}
                    isSelectAll={true}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Grid
                    sx={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                      gap: "16px",
                      flexDirection: matches && "column",
                    }}
                  >
                    <CustomDateRangePicker
                      className="sale-receivable-summary-filter"
                      fromDate={fromDate}
                      setFromDate={setFromDate}
                      toDate={toDate}
                      setToDate={setToDate}
                      minDate={dayjs(userContext.financialYearStartDate)}
                      maxDate={dayjs(userContext.financialYearEndDate)}
                    />
                    <Button
                      className="sale-receivable-summary-filter"
                      aria-controls={
                        openExportType ? "demo-customized-menu" : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={openExportType ? "true" : undefined}
                      disabled={resultData?.length <= 0 ? true : false}
                      variant="outlined"
                      disableElevation
                      onClick={handleClickExportType}
                      startIcon={<DownloadIcon />}
                      endIcon={<KeyboardArrowDownSharp />}
                    >
                      Export
                    </Button>
                    <StyledMenu
                      id="demo-customized-menu"
                      MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                      }}
                      anchorEl={anchorEl2}
                      open={openExportType}
                      onClose={handleCloseExportType}
                    >
                      <MenuItem
                        disableRipple
                        onClick={() => {
                          openOutstandingReceivableSummaryPopup("pdf");
                        }}
                      >
                        PDF
                      </MenuItem>
                      <MenuItem
                        disableRipple
                        onClick={() => {
                          openOutstandingReceivableSummaryPopup("Excel");
                        }}
                      >
                        EXCEL
                      </MenuItem>
                    </StyledMenu>
                  </Grid>
                </Grid>
              </Grid>

              <TableContainer>
                <Table
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                >
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    rowCount={resultData?.length}
                  />
                  {resultData?.length > 0 && (
                    <TableBody>
                      {stableSort(resultData, getComparator(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item: any, index: any) => {
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={index + 1}
                            >
                              <TableCell>
                                <Button
                                  disableRipple
                                  color="inherit"
                                  onClick={() => {
                                    handleMoveToOutstandingReceivable(
                                      item?.buyerId
                                    );
                                  }}
                                  className="manuButton-link"
                                  style={{ backgroundColor: "transparent" }}
                                >
                                  <span
                                    className="text-link"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {item?.name}
                                  </span>
                                </Button>
                              </TableCell>
                              <TableCell
                                className={item?.debit < 0 ? "danger" : ""}
                                sx={{
                                  display: {
                                    xs: "table-cell",
                                    sm: "table-cell",
                                    md: "table-cell",
                                  },
                                  fontWeight: 600,
                                }}
                                align="right"
                              >
                                {numberFormat(item?.debit)}
                              </TableCell>
                              <TableCell
                                className={item?.credit < 0 ? "danger" : ""}
                                sx={{
                                  display: {
                                    xs: "table-cell",
                                    sm: "table-cell",
                                    md: "table-cell",
                                  },
                                  fontWeight: 600,
                                }}
                                align="right"
                              >
                                {numberFormat(item?.credit)}
                              </TableCell>
                              <TableCell
                                className={
                                  item?.closingBalance < 0 ? "danger" : ""
                                }
                                sx={{
                                  display: {
                                    xs: "table-cell",
                                    sm: "table-cell",
                                    md: "table-cell",
                                  },
                                  fontWeight: 600,
                                }}
                                align="right"
                              >
                                {numberFormatWithCrDr(
                                  item?.closingBalance,
                                  item?.nature
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}

                      {resultData?.length <= 0 && (
                        <TableRow hover tabIndex={-1} key={"0"}>
                          <TableCell colSpan={6} align="center">
                            No record(s) found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  )}
                </Table>
                {resultData?.length > 0 && (
                  <Grid
                    container
                    paddingTop={1}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Grid item xs={12} sm={6} className="pagination-label">
                      {pageStartIndex} - {pageEndIndex} of {totalCount}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack
                        component="div"
                        alignItems={mobileScreen ? " center" : "end"}
                        justifyContent={"center"}
                      >
                        <Pagination
                          color="primary"
                          count={counData}
                          page={page_Index}
                          onChange={handleChangePageNew}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                )}
              </TableContainer>
            </Paper>
          </Grid>
        </Box>
      ) : (
        <Box
          className="boxContainer"
          sx={{
            "& > :not(style)": {
              width: "100%",
            },
          }}
        >
          <MissingConsumer consumerId={userContext.consumerId} />
        </Box>
      )}
    </>
  );
};

export default LedgerGroupSummaryAdvance;
