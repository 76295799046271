import * as React from "react";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    IconButton,
    TextField,
    Grid,
    MenuItem,
    InputAdornment,
    Pagination,
    Stack,
    Backdrop,
    useTheme,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { useLocation, useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { PAGE_SIZE } from "../../../Services/common/const";
import Title from "../../../Components/Title";
import { PreferencesContext } from "../../../PreferencesContext";
import MissingConsumer from "../../../Components/MissingConsumer";
import { ExportFilesFunction, numberFormat } from "../../../shared/CommonFuctions";
import { KeyboardArrowDownSharp, SwitchCamera } from "@mui/icons-material";
import { GetLowQuantityListRerport, GetNearExpiryListRerport, GetStockBatchWiseListReport, GetStockExpiredListRerport, GetStockReport } from "../../../Services/ReportService";
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import SplashComponent from "../../../Navigation/splash";
import { AlertType } from "../../../shared/Modal/AlertType";
import MultiSelectSim from "../../../Components/MultiSelectSimple";
import { EncryptDecryptService } from "../../../Services/common/EncryptionService";
import { GetProductListforBatchwiseListReport } from "../../../Services/CategoryProductService";
import { StyledMenu } from "../../../Components/StyleMenus";
import { GetExpiredStock, GetLowQuantityStock, GetNearExpirydStock } from "../../../Services/StockService";




interface Data {
    id: number;
    code: string;
    Product: string;
    Availability: string;
    none: string;
}
const stockList: Data = {
    id: 0,
    code: "",
    Product: "",
    Availability: "",
    none: ""
};
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = "asc" | "desc" | "";

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
) => number {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
    order: Order;
    orderBy: String;
    rowCount: number;
}


function EnhancedTableHead(props: EnhancedTableProps) {

    return (
        // checkBox for Header
        <TableHead className="table-header-color">
            <TableRow>
                <TableCell
                    sx={{
                        display: {
                            xs: "table-cell",
                            sm: "table-cell",
                            md: "table-cell",
                            lg: "table-cell",
                        },
                        fontWeight: "bold",
                        minWidth: "140px",
                    }}
                    align="left"
                >
                    {"Product"}
                </TableCell>
                <TableCell
                    sx={{
                        display: {
                            xs: "none",
                            sm: "table-cell",
                            md: "table-cell",
                            lg: "table-cell",
                        },
                        fontWeight: "bold",
                        minWidth: "70px",
                    }}
                    align="left"
                >
                    {"Unit"}
                </TableCell>

                <TableCell
                    sx={{
                        display: {
                            xs: "none",
                            sm: "table-cell",
                            md: "table-cell",
                            lg: "table-cell",
                        },
                        fontWeight: "bold",
                        minWidth: "70px",
                    }}
                    align="left"
                >
                    {"Category"}
                </TableCell>

                <TableCell
                    sx={{
                        display: {
                            xs: "none",
                            sm: "table-cell",
                            md: "table-cell",
                            lg: "table-cell",
                        },
                        fontWeight: "bold",
                        minWidth: "70px",
                    }}
                    align="left"
                >
                    {"Warehouse"}
                </TableCell>
                <TableCell
                    sx={{
                        display: {
                            xs: "none",
                            sm: "table-cell",
                            md: "table-cell",
                            lg: "table-cell",
                        },
                        fontWeight: "bold",
                        minWidth: "80px",
                    }}
                    align="left"
                >
                    {"Batch"}
                </TableCell>
                <TableCell
                    sx={{
                        display: {
                            xs: "none",
                            sm: "table-cell",
                            md: "table-cell",
                            lg: "table-cell",
                        },
                        fontWeight: "bold",
                        minWidth: "80px",
                    }}
                    align="center"
                >
                    {"Expiry"}
                </TableCell>
                <TableCell
                    sx={{
                        display: {
                            xs: "none",
                            sm: "table-cell",
                            md: "table-cell",
                            lg: "table-cell",
                        },
                        fontWeight: "bold",
                        minWidth: "80px",
                    }}
                    align="right"
                >
                    {"Opening"}
                </TableCell>
                <TableCell
                    sx={{
                        display: {
                            xs: "none",
                            sm: "table-cell",
                            md: "table-cell",
                            lg: "table-cell",
                        },
                        fontWeight: "bold",
                        minWidth: "80px",
                    }}
                    align="right"
                >
                    {"Inward"}
                </TableCell>

                <TableCell
                    sx={{
                        display: {
                            xs: "none",
                            sm: "table-cell",
                            md: "table-cell",
                            lg: "table-cell",
                        },
                        fontWeight: "bold",
                        minWidth: "80px",
                    }}
                    align="right"
                >
                    {"Outward"}
                </TableCell>

                <TableCell
                    sx={{
                        display: {
                            xs: "table-cell",
                            sm: "table-cell",
                            md: "table-cell",
                            lg: "table-cell",
                        },
                        fontWeight: "bold",
                        minWidth: "80px",
                    }}
                    align="right"
                >
                    {"Available"}
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

const parameters: any = {
    consumerId: 25,
    branchId: 1,
    pageIndex: 1,
    pageSize: PAGE_SIZE,
    sortColumn: "",
    sortDirection: "",
    searchValue: "",
    lessThanValue: "",
};

export default function StockByProductReport() {
    const theme = useTheme();
    const matches = useMediaQuery("(max-width:599px)");
    const match = useMediaQuery("(max-width:599px)");
    const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

    let navigate = useNavigate();
    const [param, setParam] = useState(parameters);
    const [anchorEl, setAnchorEl3] = React.useState<HTMLButtonElement | null>(
        null
    );
    const [pageName, setPageName] = React.useState('Stock Reports');
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const [order, setOrder] = React.useState<Order>("");
    const [orderBy, setOrderBy] = React.useState<keyof Data>("none");
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(PAGE_SIZE);
    const [value, setValue] = useState("");
    const [values, setValues] = useState("");
    const [isLoading, setLoading] = React.useState(false);
    const [isSubmitting, setSubmitting] = React.useState(false);
    const [openDilog, setopenDilog] = React.useState(false);
    const [resultData, setResultData] = useState<any>(stockList);
    const [selectedProductIdData, setselectedProductIdData] = React.useState<any>([]);
    const [productCollection, setProductCollection] = useState<any>([]);
    const [page_Index, setPage_Index] = useState(1);
    const [counData, setCounData] = useState(0);
    const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
    const [Curr_page, setCurrPage] = React.useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [pageStartIndex, setPageStartIndex] = useState(1);
    const [pageEndIndex, setPageEndIndex] = useState(1);
    const [productsSelectedOption, setProductssSelectedOption] = React.useState<any>([]);
    const { userContext, updateUserContext } = React.useContext(PreferencesContext);

    const openExportType = Boolean(anchorEl2);
    const handleClickExportType = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl2(event.currentTarget);
    };
    const handleCloseExportType = () => {
        setAnchorEl2(null);
    };



    let urlParams = useQuery();
    let finalType = urlParams.get("t") || '';
    const handleChangeCategory = (object: any) => {
        setProductssSelectedOption(object);
        let tempProductIdData: any = [];
        object.forEach((x) => {
            if (x?.value === "*") {
                return;
            } else {
                let productIds = x.productId;
                x.productId = productIds;
                tempProductIdData.push(productIds.toString());
            }
        });
        setselectedProductIdData(tempProductIdData)
    };

    async function GetExpiredProductList() {
        let params = new URLSearchParams();
        params.append("consumerId", userContext.consumerId.toString());
        params.append("firmId", userContext.firmId.toString());
        params.append("branchId", userContext.branchId.toString());
        params.append("pageIndex", page_Index.toString());
        params.append("pageSize", param.pageSize);
        params.append("sortColumn", param.sortColumn);
        params.append("sortDirection", param.sortDirection);
        params.append("searchValue", param.searchValue);
        params.append("lessThanValue", param.lessThanValue);
        params.append("financialYear", userContext.financialYear);
        params.append("productID", selectedProductIdData);
        setLoading(true);
        try {
            let result: any
            
            switch (finalType) {
                case 'expired':
                    result = await GetExpiredStock(params);
                    setPageName('Expired Reports')
                    break;

                case 'near-expiry':
                    result = await GetNearExpirydStock(params);
                    setPageName('Near Expiry Reports')
                    break;

                case 'low-quantity':
                    result = await GetLowQuantityStock(params);
                    setPageName('Low Quantity Stocks')
                    break;
                default:
                    updateUserContext({
                        ...userContext,
                        isAlert: true,
                        alertMessage: 'Contact to admin.',
                        alertType: AlertType.Error,
                    });
                    break;
            }
            let endIndex: any;
            if (result && result?.data && result?.data?.data) {

                let StockInfo: any


                switch (finalType) {
                    case 'expired':
                        StockInfo = result?.data?.data?.expiredStockListCollection;
                        break;

                    case 'near-expiry':
                        StockInfo = result?.data?.data?.nearExpiryStockListCollection;
                        break;

                    case 'low-quantity':
                        StockInfo = result?.data?.data?.lowQuantityStockListCollection;
                        break;
                    default:
                        updateUserContext({
                            ...userContext,
                            isAlert: true,
                            alertMessage: 'Contact to admin.',
                            alertType: AlertType.Error,
                        });
                        break;
                }


                let dataCount = result.data.data.totalCount;
                let showDataCount = Math.ceil(dataCount / PAGE_SIZE);
                setResultData(StockInfo);
                setCounData(showDataCount);
                let startIndex = (page_Index - 1) * param.pageSize + 1;
                endIndex =
                    (page_Index - 1) * param.pageSize +
                    (StockInfo
                        ? StockInfo.length
                        : 0);
                setPageStartIndex(startIndex);
                setPageEndIndex(endIndex);
                setTotalCount(dataCount);
            }
            else {
                console.log("Error")
            }
        }
        catch (e: any) {
            updateUserContext({
                ...userContext,
                isAlert: true,
                alertMessage: typeof e === 'string' ? e as string : e?.message as string,
                alertType: AlertType.Error,
            });
        }
        setLoading(false);

    }
    async function GetProductListforBatchwise() {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("consumerId", userContext.consumerId.toString());
        params.append("firmId", userContext.firmId.toString());
        params.append("branchId", userContext.branchId.toString());
        params.append("sortColumn", param.sortColumn);
        params.append("sortDirection", param.sortDirection);
        params.append("searchValue", param.searchValue);
        try {
            let categoryInfo: any;
            const result: any = await GetProductListforBatchwiseListReport(params);
            if (result && result.data.data) {
                categoryInfo = result.data?.data?.productCollection;
                setProductCollection(categoryInfo)
            } else {
            }
            setLoading(false);
            return categoryInfo
        } catch (e: any) {
            setLoading(false);
            updateUserContext({
                ...userContext,
                isAlert: true,
                alertMessage: typeof e === 'string' ? e as string : e?.message as string,
                alertType: AlertType.Error,
            });
        }
        setLoading(false);
    }

    function searchValue(val: any) {
        if (val === "") {
            setValue("");
        }
        setPage_Index(1);
        setParam({ ...param, searchValue: val.toString(), pageIndex: 1 });
    }

    //for less than
    function lessThanValue(val: any) {
        if (val === "") {
            setValues("");
        }
        setPage_Index(1);
        setParam({ ...param, lessThanValue: val.toString(), pageIndex: 1 });
    }


    const handleChangePageNew = (
        event: React.ChangeEvent<unknown>,
        value: number
        //values: number
    ) => {
        if (Curr_page !== value) {
            setCurrPage(value);
            setPage_Index(value);
            setParam({ ...param, pageIndex: value });
        }
    };

    async function openStockReportPopup(type: any) {

        setAnchorEl2(null);
        setLoading(true);
        let reprotName = '';
        let params = new URLSearchParams();
        switch (finalType) {
            case 'expired':
                reprotName = "StockeExpiredReport";
                break;

            case 'near-expiry':
                reprotName = "NearExpiryReport";

                break;

            case 'low-quantity':
                reprotName = "LowQuantityReport";
                break;
            default:
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: "can't able to generate the report.",
                    alertType: AlertType.Error,
                });
                break;
        }
        params.append("reportName", reprotName.toString());
        params.append("reportType", type);
        params.append("consumerId", userContext.consumerId.toString());
        params.append("branchId", userContext.branchId.toString());
        params.append("firmId", userContext.firmId.toString());
        params.append("searchValue", param.searchValue);
        params.append("lessThanValue", param.lessThanValue);
        params.append("productId", selectedProductIdData);
        params.append("financialYear", userContext.financialYear.toString());
        params.append("fileName", reprotName?.toString());
        params.append("isForDownload", "1");
        try {
            let result: any
            switch (finalType) {
                case 'expired':
                    result = await GetStockExpiredListRerport(params);
                    break;

                case 'near-expiry':
                    result = await GetNearExpiryListRerport(params);
                    break;

                case 'low-quantity':
                    result = await GetLowQuantityListRerport(params);
                    break;
                default:
                    updateUserContext({
                        ...userContext,
                        isAlert: true,
                        alertMessage: "can't able to generate the report.",
                        alertType: AlertType.Error,
                    });
                    break;
            }

            if (result && result.data && result.data.data) {
                var fileContent = result?.data?.data?.fileData;
                var attachmentData = fileContent;
                var attachmentName = result?.data?.data?.fileName;
                ExportFilesFunction(attachmentName, type, attachmentData)
            }
        } catch (e: any) {
            updateUserContext({
                ...userContext,
                isAlert: true,
                alertMessage: typeof e === 'string' ? e as string : e?.message as string,
                alertType: AlertType.Error,
            });
        }
        setLoading(false);
    }

    function useQuery() {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }



    useEffect(() => {
        GetProductListforBatchwise();
        GetExpiredProductList();

        let e: any = document.getElementById("root");
        e.scrollIntoView({
            block: "start",
            behavior: "smooth",
            inline: "start",
        });
    }, [param, selectedProductIdData]);



    return (
        <>
            <Backdrop
                sx={{
                    color: "#fff",
                    backgroundColor: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={isLoading}
            >
                <SplashComponent />
            </Backdrop>
            <Title pageTitle={pageName} />


            {userContext.consumerId > 0 ? (
                <Box
                    className="boxContainer"
                    sx={{
                        "& > :not(style)": {
                            width: "100%",
                        },
                    }}
                >
                    <Grid
                        className="title-button-responsive"
                        item
                        xs={6}
                        md={3}
                        sx={{
                            display: "flex !important",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <h4 className="page-title">{pageName}</h4>
                        <Grid
                            item
                            xs={6}
                            md={3}
                            marginRight={0}
                            textAlign="end"
                            paddingTop="0px"
                        >
                            <Button
                                // color="primary"
                                variant="outlined"
                                onClick={() => navigate(-1)}
                                size="medium"
                                startIcon={<KeyboardBackspaceRoundedIcon />}
                            >
                                Back
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid className="paper top-space">
                        <Paper elevation={4} className="paper-container">
                            <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
                                <MultiSelectSim
                                    key="productId"
                                    placeholder="Select Product.."
                                    options={productCollection}
                                    onChange={handleChangeCategory}
                                    value={productsSelectedOption}
                                    isSelectAll={true}
                                    menuPlacement={"bottom"}
                                />
                            </Grid>
                            <Grid
                                container
                                spacing={1}
                                sx={{ marginTop: "10px" }}
                                className="search-space"
                            >
                                <Grid item xs={12} sm={4} md={3} lg={2} xl={2} >
                                    <TextField
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                searchValue(value);
                                            }
                                        }}
                                        sx={{ paddingLeft: "0px !important", width: '100%' }}
                                        className="button-responsive-stock"
                                        placeholder="Search..."
                                        type="text"
                                        variant="outlined"
                                        size="small"
                                        onChange={(e) => setValue(e.target.value)}
                                        value={value}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={() => searchValue(value)}>
                                                        <SearchIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),

                                            endAdornment: value && (
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => searchValue("")}
                                                >
                                                    <ClearIcon />
                                                </IconButton>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} md={3} lg={2} xl={2} >
                                    <TextField
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                lessThanValue(values);
                                            }
                                        }}
                                        sx={{ width: '100%' }}
                                        className="button-responsive-stock"
                                        placeholder="Stock is less than..."
                                        type="number"
                                        variant="outlined"
                                        size="small"
                                        onChange={(e) => setValues(e.target.value)}
                                        value={values}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={() => lessThanValue(values)}>
                                                        <SearchIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),

                                            endAdornment: values && (
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => lessThanValue("")}
                                                >
                                                    <ClearIcon />
                                                </IconButton>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} md={6} lg={8} xl={8}>
                                    <Grid
                                        sx={{
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "end",
                                            alignItems: "center",
                                        }}>
                                        <Button
                                            className="button-responsive-stock"
                                            aria-controls={
                                                openExportType ? "demo-customized-menu" : undefined
                                            }
                                            aria-haspopup="true"
                                            aria-expanded={openExportType ? "true" : undefined}
                                            disabled={
                                                resultData?.length <= 0 ? true : false
                                            }
                                            variant="outlined"
                                            disableElevation
                                            onClick={handleClickExportType}
                                            startIcon={<DownloadIcon />}
                                            endIcon={<KeyboardArrowDownSharp />}
                                        >
                                            Export
                                        </Button>
                                        <StyledMenu
                                            id="demo-customized-menu"
                                            MenuListProps={{
                                                "aria-labelledby": "demo-customized-button",
                                            }}
                                            anchorEl={anchorEl2}
                                            open={openExportType}
                                            onClose={handleCloseExportType}
                                        >
                                            <MenuItem
                                                disableRipple
                                                onClick={() => {
                                                    openStockReportPopup("pdf");
                                                }}
                                            >
                                                PDF
                                            </MenuItem>
                                            <MenuItem
                                                disableRipple
                                                onClick={() => {
                                                    openStockReportPopup("Excel");
                                                }}
                                            >
                                                EXCEL
                                            </MenuItem>
                                        </StyledMenu>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <TableContainer>
                                <Table
                                    aria-labelledby="tableTitle"
                                    size={dense ? "small" : "medium"}
                                >
                                    <EnhancedTableHead
                                        order={order}
                                        orderBy={orderBy}
                                        rowCount={resultData?.length}
                                    />
                                    {resultData?.length > 0 &&
                                        <TableBody>
                                            {stableSort(
                                                resultData,
                                                getComparator(order, orderBy)
                                            )
                                                .slice(
                                                    page * rowsPerPage,
                                                    page * rowsPerPage + rowsPerPage
                                                )
                                                .map((item: any, index: any) => {
                                                    const labelId = `enhanced-table-checkbox-${index}`;
                                                    return (
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            key={index + 1}
                                                        >
                                                            <TableCell
                                                                component="th"
                                                                id={labelId}
                                                                scope="row"
                                                                align="left"
                                                            >
                                                                <span >
                                                                    {item.productName}
                                                                </span>
                                                            </TableCell>

                                                            <TableCell
                                                                sx={{
                                                                    display: {
                                                                        xs: "none",
                                                                        sm: "table-cell",
                                                                        md: "table-cell",
                                                                    },
                                                                }}
                                                                align="left"
                                                            >
                                                                <span>{item?.unitName}</span>
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    display: {
                                                                        xs: "none",
                                                                        sm: "table-cell",
                                                                        md: "table-cell",
                                                                    },
                                                                }}
                                                                align="left"
                                                            >
                                                                <span>{item?.categoryName}</span>
                                                            </TableCell>

                                                            <TableCell
                                                                sx={{
                                                                    display: {
                                                                        xs: "none",
                                                                        sm: "table-cell",
                                                                        md: "table-cell",
                                                                    },
                                                                }}
                                                                align="left"
                                                            >
                                                                <span>{item?.warehouseName}</span>
                                                            </TableCell>

                                                            <TableCell
                                                                sx={{
                                                                    display: {
                                                                        xs: "none",
                                                                        sm: "table-cell",
                                                                        md: "table-cell",
                                                                    },
                                                                }}
                                                                align="left"
                                                            >
                                                                {item?.batchNumber}
                                                            </TableCell>

                                                            <TableCell
                                                                sx={{
                                                                    display: {
                                                                        xs: "none",
                                                                        sm: "table-cell",
                                                                        md: "table-cell",
                                                                    },
                                                                }}
                                                                align="center"
                                                            >
                                                                {item?.expiryDate}
                                                            </TableCell>

                                                            <TableCell
                                                                sx={{
                                                                    display: {
                                                                        xs: "none",
                                                                        sm: "table-cell",
                                                                        md: "table-cell",
                                                                    },
                                                                }}
                                                                align="right"
                                                            >
                                                                {numberFormat(item?.openingQuantity)}
                                                            </TableCell>

                                                            <TableCell
                                                                sx={{
                                                                    display: {
                                                                        xs: "none",
                                                                        sm: "table-cell",
                                                                        md: "table-cell",
                                                                    },
                                                                }}
                                                                align="right"
                                                            >
                                                                {numberFormat(item.inward)}
                                                            </TableCell>

                                                            <TableCell
                                                                sx={{
                                                                    display: {
                                                                        xs: "none",
                                                                        sm: "table-cell",
                                                                        md: "table-cell",
                                                                    },
                                                                }}
                                                                align="right"
                                                            >
                                                                {numberFormat(item.outward)}
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    display: {
                                                                        xs: "table-cell",
                                                                        sm: "table-cell",
                                                                        md: "table-cell",
                                                                    },
                                                                }}
                                                                align="right"
                                                                className={
                                                                    item?.availabelQuantity <= 0
                                                                        ? "red-color"
                                                                        : item?.availabelQuantity <= 10
                                                                            ? "blue-color"
                                                                            : ""
                                                                }
                                                            >
                                                                {numberFormat(item?.availabelQuantity)}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            {resultData?.length > 0 && (
                                                <TableRow hover tabIndex={-1} key={"0"}>
                                                    <TableCell className="bold-600">Total</TableCell>

                                                    <TableCell align="right" className="bold-600 double-underline"
                                                        sx={{
                                                            display: {
                                                                xs: "none",
                                                                sm: "table-cell",
                                                                md: "table-cell",
                                                                lg: "table-cell",
                                                            }
                                                        }}
                                                    >
                                                        { }
                                                    </TableCell>
                                                    <TableCell align="right" className="bold-600 double-underline"
                                                        sx={{
                                                            display: {
                                                                xs: "none",
                                                                sm: "table-cell",
                                                                md: "table-cell",
                                                                lg: "table-cell",
                                                            }
                                                        }}
                                                    >
                                                        {""}
                                                    </TableCell>
                                                    <TableCell align="right" className="bold-600 double-underline"
                                                        sx={{
                                                            display: {
                                                                xs: "none",
                                                                sm: "table-cell",
                                                                md: "table-cell",
                                                                lg: "table-cell",
                                                            }
                                                        }}
                                                    >
                                                        {""}
                                                    </TableCell>
                                                    <TableCell align="right" className="bold-600 double-underline"
                                                        sx={{
                                                            display: {
                                                                xs: "none",
                                                                sm: "table-cell",
                                                                md: "table-cell",
                                                                lg: "table-cell",
                                                            }
                                                        }}
                                                    >
                                                        {""}
                                                    </TableCell>
                                                    <TableCell align="right" className="bold-600 double-underline"
                                                        sx={{
                                                            display: {
                                                                xs: "none",
                                                                sm: "table-cell",
                                                                md: "table-cell",
                                                                lg: "table-cell",
                                                            }
                                                        }}
                                                    >
                                                        {""}
                                                    </TableCell>
                                                    <TableCell align="right" className="bold-600 double-underline"
                                                        sx={{
                                                            display: {
                                                                xs: "none",
                                                                sm: "table-cell",
                                                                md: "table-cell",
                                                                lg: "table-cell",
                                                            }
                                                        }}
                                                    >
                                                        {numberFormat(resultData?.reduce((acc, curr) => acc + (curr?.openingQuantity ? curr?.openingQuantity : 0), 0))}
                                                    </TableCell>
                                                    <TableCell align="right" className="bold-600 double-underline"
                                                        sx={{
                                                            display: {
                                                                xs: "none",
                                                                sm: "table-cell",
                                                                md: "table-cell",
                                                                lg: "table-cell",
                                                            }
                                                        }}
                                                    >
                                                        {numberFormat(resultData?.reduce((acc, curr) => acc + (curr?.inward ? curr?.inward : 0), 0))}
                                                    </TableCell>
                                                    <TableCell align="right" className="bold-600 double-underline"
                                                        sx={{
                                                            display: {
                                                                xs: "none",
                                                                sm: "table-cell",
                                                                md: "table-cell",
                                                                lg: "table-cell",
                                                            }
                                                        }}
                                                    >
                                                        {numberFormat(resultData?.reduce((acc, curr) => acc + (curr?.outward ? curr?.outward : 0), 0))}
                                                    </TableCell>
                                                    <TableCell align="right" className="bold-600 double-underline">
                                                        {numberFormat(resultData?.reduce((acc, curr) => acc + (curr?.availabelQuantity ? curr?.availabelQuantity : 0), 0))}
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                            {resultData?.length <= 0 && (
                                                <TableRow hover tabIndex={-1} key={"0"}>
                                                    <TableCell colSpan={6} align="center">
                                                        No record(s) found
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>}
                                </Table>
                                {
                                    resultData?.length > 0 &&
                                    <Grid
                                        container
                                        paddingTop={1}
                                        alignItems={"center"}
                                        justifyContent={"center"}
                                    >
                                        <Grid item xs={12} sm={6}
                                            className="pagination-label"
                                        >
                                            {pageStartIndex} - {pageEndIndex} of {totalCount}
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Stack
                                                component="div"
                                                alignItems={mobileScreen ? " center" : "end"}
                                                justifyContent={"center"}
                                            >
                                                <Pagination
                                                    color="primary"
                                                    count={counData}
                                                    page={page_Index}
                                                    onChange={handleChangePageNew}
                                                />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                }
                            </TableContainer>
                        </Paper>
                    </Grid>
                </Box>
            ) : (
                <Box
                    className="boxContainer"
                    sx={{
                        "& > :not(style)": {
                            width: "100%",
                        },
                    }}
                >
                    <MissingConsumer consumerId={userContext.consumerId} />
                </Box>
            )}
        </>
    );
}
