import * as React from "react";
import Button from "@mui/material/Button";
import "../../Theme/Palette/variable.scss";
import "../../Theme/main.scss";
import {
  Box,
  FormControl,
  Grid,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  Backdrop,
} from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import {
  GetUserGroupDetail,
  CreateUserGroup,
  UpdateUserGroup,
} from "../../Services/UserGroupService";
import LoadingButton from "@mui/lab/LoadingButton";
import { PreferencesContext } from "../../PreferencesContext";
import { AlertType } from "../../shared/Modal/AlertType";
import { EncryptDecryptService } from "../../Services/common/EncryptionService";
import SplashComponent from "../../Navigation/splash";
import Title from "../../Components/Title";
import { convertToUpperCase, isElementVisible } from "../../shared/CommonFuctions";
import CustomAutoFocus from "../../Components/CustomAutoFocus";

export default function UserGroupSave(props: any) {
  //Destructure the props For creatable
  const { code, isForPopup, handleUserGroupChange, handleCloseorOpenpopup } = props;


  let formInitialValues =
  {
    userGroupId: 0,
    code: "",
    name: (isForPopup && isForPopup !== undefined) ? code : ""
  };

  let location = useLocation();
  const { isAddPopUp } =
    (location && location.state) || props.state || {};

  const Productdata = {
    title: "Add Product",
    button: "Save",
    productId: 0,
    userGroupIdForPopUp: true,
    taxIdForPopUp: false,
  };
  const matches = useMediaQuery("(max-width:599px)");
  const [isSubmitting, setSubmitting] = useState(false);
  const [userGroupinitial, setUserGroupInitial] = useState(formInitialValues);
  const { userContext, updateUserContext } = useContext(PreferencesContext);
  const [isLoading, setLoading] = useState(false);
  const [state, setState] = useState(Productdata);
  const [open, setOpen] = useState(true);
  const { setInputRef } = CustomAutoFocus(isForPopup, true);
  const edService = new EncryptDecryptService();

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let navigate = useNavigate();
  const handleCancelUserGroup = () => {
    if (isForPopup === true) {
      handleCloseorOpenpopup(code);
    } else {
      navigate(-1);
    }
  };

  let urlParams = useQuery();
  let curRawUserGroupId = urlParams.get("q");
  let curUserGroupId = 0;
  if (!isForPopup) {
    if (curRawUserGroupId !== null && curRawUserGroupId !== undefined) {
      let decId = edService.decrypt(curRawUserGroupId);
      if (decId !== "" && decId !== null && decId !== undefined) {
        curUserGroupId = +decId;
      } else {
        navigate("/not-found");
      }
    } else {
      navigate("/not-found");
    }
  }

  const [userGroupId, setUserGroupId] = useState(curUserGroupId);


  //Get Data
  async function fetchData() {
    try {
      setLoading(true);
      let params = new URLSearchParams();
      params.append("consumerId", userContext.consumerId.toString());
      params.append("firmId", userContext.firmId.toString());
      params.append("branchId", userContext.branchId.toString());
      params.append("userGroupId", userGroupId.toString());
      const result: any = await GetUserGroupDetail(params);
      let editDetails = result.data.data;

      let updatedValue = {
        userGroupId: editDetails.userGroupId,
        code: editDetails.code,
        name: editDetails.name,
      };
      setLoading(false);
      setUserGroupInitial(updatedValue);
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: typeof e === 'string' ? e as string : e?.message as string,
        alertType: AlertType.Error,
      });
    }


  }

  //Submit Form (UserGroup)
  async function SubmitUserGroup(value: any) {
    setSubmitting(true);
    let userGroupData = value;
    let SaveUpdateUserGroup = {
      userGroupId: userGroupData.userGroupId,
      consumerId: userContext.consumerId,
      firmId: userContext.firmId,
      branchid: userContext.branchId,
      // oldCode: userGroupinitial.code,
      oldName: userGroupinitial.name,
      code: userGroupData.code.trim(),
      Name: userGroupData.name.trim(),
      dataManagedBy: userContext.userId,
    };
    if (!isForPopup && userGroupData.userGroupId > 0) {
      try {
        const resultUserGroupUpdate: any = await UpdateUserGroup(SaveUpdateUserGroup);
        if (resultUserGroupUpdate && resultUserGroupUpdate.data === true) {
          setSubmitting(false);
          updateUserContext({
            ...userContext,
            isAlert: true,
            alertMessage: resultUserGroupUpdate.message,
            alertType: AlertType.Success,
          });
          if (isAddPopUp !== true) {
            navigate(-1);
          } else {
            navigate({
              pathname: "/ProductSave",
            }, {
              state,
            });
          }
        }
      } catch (e: any) {
        setSubmitting(false);
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: typeof e === 'string' ? e as string : e?.message as string,
          alertType: AlertType.Error,
        });
      }
    } else {
      try {
        const resultUserGroupSave: any = await CreateUserGroup(SaveUpdateUserGroup);

        if (resultUserGroupSave && resultUserGroupSave.data === true) {
          setSubmitting(false);
          updateUserContext({
            ...userContext,
            isAlert: true,
            alertMessage: resultUserGroupSave.message,
            alertType: AlertType.Success,
          });
          if (isForPopup === true) {
            handleCloseorOpenpopup(code);
          } else {
            if (isAddPopUp !== true) {
              navigate(-1);
            } else {
              navigate({
                pathname: "/ProductSave",
              }, {
                state,
              });
            }
          }
        }
      } catch (e: any) {
        setSubmitting(false);
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: typeof e === 'string' ? e as string : e?.message as string,
          alertType: AlertType.Error,
        });
      }
    }
  }

  // const handleOpendialog = () => {
  //   setOpen(true);
  // }

  useEffect(() => {

    if (userGroupId > 0) {
      fetchData();
    }
  }, []);

  const handleFocusOnKeyDown = (event: any) => {
    if (event.keyCode === 13 && event.target.nodeName === "INPUT") {
      let form = event.target.form;
      let index = Array.prototype.indexOf.call(form, event.target);
      let Elements = form.elements;

      let nextIndex = index + 2;
      while ((nextIndex < Elements.length && !isElementVisible(Elements[nextIndex])) || (Elements[nextIndex]?.disabled === true)) {
        nextIndex++;
      }
      if (nextIndex < Elements.length) {
        Elements[nextIndex].focus();
      }
      event.preventDefault();
    }
  }

  React.useEffect(() => {
    document.addEventListener("keydown", handleFocusOnKeyDown);
    return () => {
      document.removeEventListener("keydown", handleFocusOnKeyDown);
    }
  }, []);

  return (
    <>
      <Title pageTitle="UserGroup" />
      <Formik
        enableReinitialize
        initialValues={userGroupinitial}
        initialTouched={{
          code: true,
          name: true,
        }}
        validateOnMount={true}
        onSubmit={(values) => {
          SubmitUserGroup(values);
        }}
        validationSchema={Yup.object().shape({
          // code: Yup.string().required("Code is required."),
          name: Yup.string().required("Name is required."),
        })}
      >
        {(props) => {
          return (
            <React.Fragment>
              <Backdrop
                sx={{
                  color: "#fff",
                  backgroundColor: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={isLoading}
              >
                <SplashComponent />
              </Backdrop>
              <Box
                className="boxContainer PageCenter"
                sx={{
                  maxWidth: "500px",
                  marginTop: isForPopup ? "0px !important" : "16px",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <h4
                    className="page-title"
                    style={{
                      borderBottom: isForPopup ? "1px solid #ced4da" : "none",
                      padding: isForPopup ? "16px" : "0px !important",
                      width: "100%",
                      position: isForPopup ? "sticky" : "static",
                      top: "0px",
                      zIndex: 9,
                    }}
                  >
                    {userGroupId > 0 ? "Edit User Group" : "Add User Group"}
                  </h4>
                </Box>
                <Grid className="paper" >
                  <Paper
                    elevation={1}
                    className={!isForPopup ? "paper-container" : ""}
                    sx={{
                      border: isForPopup
                        ? "none !important"
                        : "1px solid $card-border-color",
                    }}
                  >
                    <Form onSubmit={props.handleSubmit} noValidate>
                      <Grid
                        container
                        spacing={2}
                        paddingX={isForPopup ? 1 : 0}
                        marginTop={isForPopup ? 0.25 : 0}
                      >
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <TextField
                              id="code"
                              label="Code"
                              fullWidth
                              type='text'
                              size="small"
                              autoFocus
                              inputRef={isForPopup ? setInputRef : setInputRef}
                              onChange={(e:any) => {
                                props.handleChange(e);
                              }}
                              value={props.values.code}
                              onBlur={props.handleBlur}
                              error={
                                props.errors.code && props.touched.code
                                  ? true
                                  : false
                              }
                            />
                            {props.errors.code && props.touched.code && (
                              <Typography
                                className={
                                  props.errors.code && props.touched.code
                                    ? "validation-message text-input error"
                                    : "validation-message text-input"
                                }
                              >
                                {props.errors.code}
                              </Typography>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <TextField
                              required
                              id="name"
                              label="Name"
                              fullWidth
                              size="small"
                              inputProps={{ maxLength: 100 }}
                              onChange={(e) => {
                                props.handleChange(e);
                                if (isForPopup) {
                                  handleUserGroupChange(e.target.value);
                                }
                              }}
                              value={props.values.name}
                              onBlur={props.handleBlur}
                              error={
                                props.errors.name && props.touched.name
                                  ? true
                                  : false
                              }
                            />
                            {props.errors.name && props.touched.name && (
                              <Typography
                                className={
                                  props.errors.name && props.touched.name
                                    ? "validation-message text-input error"
                                    : "validation-message text-input"
                                }
                              >
                                {props.errors.name}
                              </Typography>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        columnGap={2}
                        marginTop={2}
                        paddingY={2}
                        style={{
                          width: "100%",
                          backgroundColor: "white",
                          position: isForPopup ? "sticky" : "static",
                          bottom: "0px",
                          zIndex: 9,
                          borderTop: isForPopup ? "1px solid #ced4da" : "none",
                          boxShadow: isForPopup
                            ? " 0px -10px 5px -10px #ced4da"
                            : "none",
                        }}
                      >
                        <Grid item xs={5.5} textAlign="right">
                          <LoadingButton
                            id="userGroupButton"
                            color="primary"
                            type="submit"
                            // loadingPosition="start"
                            // startIcon={<></>}
                            variant="contained"
                          >
                            {userGroupId > 0 ? "Update" : "Save"}
                          </LoadingButton>
                        </Grid>
                        <Grid item xs={5.5}>
                          <Button variant="outlined" onClick={handleCancelUserGroup}>
                            Cancel
                          </Button>
                        </Grid>
                      </Grid>
                    </Form>
                  </Paper>
                </Grid>
              </Box>
            </React.Fragment>
          );
        }}
      </Formik>
    </>
  );
}
