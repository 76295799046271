import * as React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Popover,
  Button,
  Tooltip,
  IconButton,
  TextField,
  Grid,
  Pagination,
  Stack,
  Backdrop,
  ListItem,
  ListItemText,
  ListItemButton,
  List,
  Typography,
  Checkbox,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AsyncSelect from "react-select/async";
import AddIcon from "@mui/icons-material/Add";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { InputAdornment } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useState, useEffect, useContext } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import {
  GetUserGroupList,
  DeleteUserGroup,
  GetUserGroupMemberList,
  TransferMembersToAnotherUserGroup,
} from "../../Services/UserGroupService";
import { PAGE_SIZE } from "../../Services/common/const";
import Title from "../../Components/Title";
import { PreferencesContext } from "../../PreferencesContext";
import { AlertType } from "../../shared/Modal/AlertType";
import MissingConsumer from "../../Components/MissingConsumer";
import { EncryptDecryptService } from "../../Services/common/EncryptionService";
import { styled, alpha, useTheme } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DownloadIcon from "@mui/icons-material/Download";
import { KeyboardArrowDownSharp } from "@mui/icons-material";
import SplashComponent from "../../Navigation/splash";
import { AsyncCustomSelect } from "../../Components/CustomSelect";

interface Data {
  id: number;
  code: string;
  Name: string;
  none: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc" | "";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
  numSelected: number;
  //onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  return (
    // checkBox for Header
    <TableHead>
      <TableRow>
        <TableCell
          sx={{
            display: {
              xs: "table-cell",
              sm: "table-cell",
              md: "table-cell",
              lg: "table-cell",
            },
            fontWeight: "bold",
          }}
          align="left"
        >
          {"Group"}
        </TableCell>
        <TableCell sx={{ width: "5%" }}></TableCell>
      </TableRow>
    </TableHead>
  );
}

const UserGroup: any = {
  userGroupCollection: [],
};
const edService = new EncryptDecryptService();

const parameters: any = {
  pageIndex: 1,
  pageSize: PAGE_SIZE,
  sortColumn: "",
  sortDirection: "",
  searchValue: "",
};

interface IUpdatedGroup {
  label: "";
  userGroupId: number;
  value: number;
}
export default function UserGroupList() {
  const updatedGroupColl: IUpdatedGroup = {
    label: "",
    userGroupId: 0,
    value: 0,
  };

  interface SubmitDetail {
    oldUserGroupId: number;
    initUserGroupId: number;
    initUserGroupName: string;
    updatedGroupData: [IUpdatedGroup];
  }

  const formInitialValues: SubmitDetail = {
    oldUserGroupId: 0,
    initUserGroupId: 0,
    initUserGroupName: "",
    updatedGroupData: [updatedGroupColl],
  };

  const UserGroupdata = {
    title: "Add UserGroup",
    button: "Save",
    userGroupId: 0,
    isAddPopUp: false,
  };

  const handleAddUserGroup = (e: any) => {
    let encUserGroupId: any = edService.encrypt(0);
    e.preventDefault();
    navigate(
      {
        pathname: "/user-group-save",
        search: `?q=${encodeURIComponent(encUserGroupId)}`,
      },
      {
        state,
      }
    );
  };
  const handleOpenMore = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: any,
    item: any
  ) => {
    setAnchorEl3(event.currentTarget);
    setUserGroupId(id);
    setUserGroupName(item.label);
  };

  const handleCloseMore = () => {
    setAnchorEl3(null);
  };
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const mediumDevice = useMediaQuery("(max-width:899px)");
  const matches = useMediaQuery("(max-width:599px)");
  const [state, setState] = useState(UserGroupdata);
  const [param, setParam] = useState(parameters);
  const [submitData, setSubmitData] = useState(formInitialValues);
  const [anchorEl, setAnchorEl3] = useState<HTMLButtonElement | null>(null);
  const [selected, setSelected] = useState<readonly number[]>(
    UserGroup.userGroupCollection
  );
  const [userGroupId, setUserGroupId] = useState(null);
  const [userGroupName, setUserGroupName] = useState(null);
  const open = Boolean(anchorEl);
  const [totalCount, setTotalCount] = useState(0);
  const [pageStartIndex, setPageStartIndex] = useState(1);
  const [pageEndIndex, setPageEndIndex] = useState(1);
  // const userGroupid = open ? "simple-popover" : undefined;
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [value, setValue] = useState("");
  const [openDeleteModal, setopenDeleteModal] = React.useState(false);
  const [order, setOrder] = useState<Order>("");
  const [orderBy, setOrderBy] = useState<keyof Data>("none");
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE);
  const [resultData, setResultData] = useState(UserGroup);
  const [page_Index, setPage_Index] = useState(1);
  const [counData, setCounData] = useState(0);
  const { userContext, updateUserContext } = useContext(PreferencesContext);
  const [isLoading, setLoading] = useState(false);
  const [isAllowAdd, setAllowAdd] = useState(true);

  const [openListOfDeletePersonModal, setOpenListOfDeletePersonModal] =
    useState(false);
  const [openUserGroupMemberList, setOpenUserGroupMemberList] = useState(false);
  const [Curr_page, setCurrPage] = useState(1);

  const [userGroupMemberList, setUserGroupMemberList] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [checkedItems, setCheckedItems] = useState([...userGroupMemberList]);
  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const validateSchema = Yup.object().shape({
    initUserGroupId: Yup.string().required("Please select a group."),
    // initUserGroupName: Yup.string().required('Please select a group name.'),
    // updatedGroupData: Yup.array().of(
    //   Yup.object().shape({
    //     value: Yup.string().required('Please select a user group.'),
    //     userGroupId: Yup.string().required('Please select a group ID.'),
    //     label: Yup.string().required('Please select a label.'),
    //   })
    // ),
  });

  // const handleOpenDeleteUserGroupListModal = () => {
  //   // setopenDeleteModal(true);
  //   // openUserGroupList(userGroupId)
  //   // setOpenListOfDeletePersonModal(true);
  // };

  const handleOpenDeleteUserGroupModal = (userGroupId) => {
    if (userGroupMemberList.length > 0) {
      openUserGroupList(userGroupId);
    } else {
      setopenDeleteModal(true);
    }
  };

  const handleCheckSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = resultData.userGroupCollection.map(
        (n: any) => n.userGroupId
      );
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // Pagination
  const handleChangePageNew = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    if (Curr_page !== value) {
      setCurrPage(value);
      setPage_Index(value);
      setParam({ ...param, pageIndex: value });
      fetchUserGroupList();
    }
  };

  //  Searching
  function searchValue(val: any) {
    if (val === "") {
      setValue("");
    }
    setPage_Index(1);
    setParam({ ...param, searchValue: val.toString(), pageIndex: 1 });
  }

  let navigate = useNavigate();

  async function fetchUserGroupList() {
    try {
      //new http for API
      setLoading(true);
      if (
        userContext != null &&
        userContext.consumerId != null &&
        userContext.consumerId > 0
      ) {
        setAllowAdd(true);
      } else {
        setAllowAdd(false);
      }
      let params = new URLSearchParams();
      params.append("consumerId", userContext.consumerId.toString());
      params.append("firmId", userContext.firmId.toString());
      params.append("branchId", userContext.branchId.toString());
      params.append("pageIndex", page_Index.toString());
      params.append("pageSize", param.pageSize);
      params.append("sortColumn", param.sortColumn);
      params.append("sortDirection", param.sortDirection);
      params.append("searchValue", param.searchValue);
      params.append("mode", "");
      const result: any = await GetUserGroupList(params);
      if (result && result.data.data) {
        let dataCount = result.data.data.totalCount;
        let userGroupInfo = result.data.data;
        let showDataCount = Math.ceil(dataCount / PAGE_SIZE);

        setResultData(userGroupInfo);
        setCounData(showDataCount);
        let startIndex = (page_Index - 1) * param.pageSize + 1;
        let endIndex =
          (page_Index - 1) * param.pageSize +
          (result.data.data.userGroupCollection
            ? result.data.data.userGroupCollection.length
            : 0);

        setPageStartIndex(startIndex);
        setPageEndIndex(endIndex);
        setTotalCount(dataCount);
      } else {
        console.log("Error");
      }
      setLoading(false);
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage:
          typeof e === "string" ? (e as string) : (e?.message as string),
        alertType: AlertType.Error,
      });
    }
  }

  async function handleDeleteUserGroup(userGroupid: any) {
    try {
      let DeleteData = {
        ids: userGroupid.toString(),
        consumerId: userContext.consumerId,
        firmId: userContext.firmId,
        branchId: userContext.branchId,
        isDeleted: true,
        dataManagedBy: 1,
      };
      const result: any = await DeleteUserGroup(DeleteData);
      if (result) {
        fetchUserGroupList();
        setAnchorEl3(null);
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: result.message,
          alertType: AlertType.Success,
        });
      } else {
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: result.message,
          alertType: AlertType.Error,
        });
      }
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage:
          typeof e === "string" ? (e as string) : (e?.message as string),
        alertType: AlertType.Error,
      });
    }
    setopenDeleteModal(false);
  }

  async function openUserGroupList(userGroupid: any) {
    try {
      setLoading(true);
      let params = new URLSearchParams();
      params.append("consumerId", userContext.consumerId.toString());
      params.append("firmId", userContext.firmId.toString());
      params.append("branchId", userContext.branchId.toString());
      params.append("userGroupId", userGroupid.toString());
      const result: any = await GetUserGroupMemberList(params);
      setUserGroupMemberList(result.data.data);
      fetchUserGroupList();
      setAnchorEl3(null);

      if (result.data.data.length > 0) {
        setOpenListOfDeletePersonModal(true);
      } else {
        setopenDeleteModal(true);
      }
    } catch (error: any) {}
  }

  const handleCloseDeleteUserGroupModal = () => {
    setOpenListOfDeletePersonModal(false);
    setopenDeleteModal(false);
    fetchUserGroupList();
    setAnchorEl3(null);
  };

  async function handleEditUserGroup(userGroupId: any) {
    try {
      let encUserGroupId: any = edService.encrypt(userGroupId);
      navigate(
        {
          pathname: "/user-group-save",
          search: `?q=${encodeURIComponent(encUserGroupId)}`,
        },
        {
          state: {
            title: " Edit UserGroup",
            button: "Update",
            userGroupId: userGroupId,
            isAddPopUp: "false",
          },
        }
      );
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage:
          typeof e === "string" ? (e as string) : (e?.message as string),
        alertType: AlertType.Error,
      });
    }
  }

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);

    // Update all checkboxes to match the "All" checkbox
    const updatedItems: any = userGroupMemberList.map((item: any) => ({
      ...item,
      isDeleted: false,
    }));

    setCheckedItems(updatedItems);
    setUserGroupMemberList(updatedItems);
  };

  const handleCheckboxChange = (index) => {
    const updatedItems: any = userGroupMemberList.map((item: any, idx) =>
      idx === index ? { ...item, isDeleted: false } : item
    );

    setCheckedItems(updatedItems);
    setUserGroupMemberList(updatedItems);

    // Check if all checkboxes are now checked
    const allChecked: any = updatedItems.every((item) => item.isDeleted);
    setSelectAll(allChecked);
  };

  const handleDropdownChange = (selectedOption: any, props: any) => {
    // checkbox checked
    const isChecked = true;
    setSelectAll(isChecked);

    // Update all checkboxes to match the "All" checkbox
    const updatedItems: any = userGroupMemberList.map((item: any) => ({
      ...item,
      isDeleted: isChecked,
    }));
    setCheckedItems(updatedItems);
    setUserGroupMemberList(updatedItems);

    props.setFieldValue("initUserGroupId", selectedOption.value);
    props.setFieldValue("initUserGroupName", selectedOption.label);
    props.setFieldValue("oldUserGroupId", userGroupId);

    // Update all the user group dropdowns in the list
    userGroupMemberList.forEach((item: any, index: number) => {
      props.setFieldValue(
        `updatedGroupData[${index}].userGroupId`,
        selectedOption.value
      );
      props.setFieldValue(
        `updatedGroupData[${index}].label`,
        selectedOption.label
      );
      props.setFieldValue(
        `updatedGroupData[${index}].value`,
        item.invitationId
      );
    });
  };

  useEffect(() => {
    fetchUserGroupList();
    setSelected([]);
    let e: any = document.getElementById("root");
    e.scrollIntoView({
      block: "start",
      behavior: "smooth",
      inline: "start",
    });
  }, [param]);

  //Do Not Remove This (Sorting-Data)

  // const handleRequestSort = (
  //   event: React.MouseEvent<unknown>,
  //   property: keyof Data,
  // ) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  // const handleCheckSelectSingle = (
  //   event: React.MouseEvent<unknown>,
  //   id: number
  // ) => {
  //   const selectedIndex = selected.indexOf(id);
  //   let newSelected: readonly number[] = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, id);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelected(newSelected);
  // };

  // report export start....
  const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 50,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  // async function openUserGroupDailyReportPopup(type: any) {
  //   setAnchorEl2(null);
  //   setLoading(true);
  //   let params = new URLSearchParams();
  //   params.append("reportName", "UserGroupReport");
  //   params.append("reportType", type);
  //   params.append("consumerId", userContext.consumerId.toString());
  //   params.append("firmId", userContext.firmId.toString());
  //   params.append("branchId", userContext.branchId.toString());
  //   params.append("financialYear", userContext.financialYear.toString());
  //   try {
  //     const result: any = await GetUserGroupReport(params);
  //     if (result && result.data && result.data.data) {
  //       var fileContent = result?.data?.data?.fileData;
  //       var attachmentData = fileContent;
  //       var attachmentName = ''
  //       if (type === 'pdf') {
  //         attachmentName = "UserGroups.pdf";
  //       }
  //       else {
  //         attachmentName = "UserGroups.xls";
  //       }
  //       ExportFilesFunction(attachmentName, type, attachmentData)
  //     }
  //     setLoading(false);
  //   } catch (e: any) {
  //     setLoading(false);
  //     updateUserContext({
  //       ...userContext,
  //       isAlert: true,
  //       alertMessage: typeof e === 'string' ? e as string : e?.message as string,
  //       alertType: AlertType.Error,
  //     });
  //   }
  // }

  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
  const openExportType = Boolean(anchorEl2);
  const handleClickExportType = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleCloseExportType = () => {
    setAnchorEl2(null);
  };
  // report export end....

  async function submitForm(values) {
    const oldDetail = values;
    const userGroupUpdatedData = values.updatedGroupData;
    // const formData = new FormData();
    // formData.append('consumerId', userContext.consumerId.toString());
    // formData.append('firmId', userContext.firmId.toString());
    // formData.append('branchId', userContext.branchId.toString());
    // formData.append('oldUserGroupId', oldDetail.oldUserGroupId.toString());
    // formData.append('dataManagedBy', userContext.userId.toString());
    // formData.append('transferMembersData', JSON.stringify(userGroupUpdatedData));

    let data = {
      consumerId: userContext.consumerId,
      firmId: userContext.firmId,
      branchId: userContext.branchId,
      oldUserGroupId: oldDetail.oldUserGroupId,
      dataManagedBy: userContext.userId,
      transferMembersData: JSON.stringify(userGroupUpdatedData),
    };

    try {
      const resultOfTransferMembers: any =
        await TransferMembersToAnotherUserGroup(data);
      setOpenListOfDeletePersonModal(false);
      setSelectAll(false);
      fetchUserGroupList();
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: resultOfTransferMembers.message,
        alertType: AlertType.Success,
      });
    } catch (error: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: error as string,
        alertType: AlertType.Error,
      });
    }
  }
  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          backgroundColor: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isLoading}
      >
        <SplashComponent />
      </Backdrop>
      <Title pageTitle="UserGroups" />
      <Box
        className="boxContainer"
        sx={{
          "& > :not(style)": {
            width: "100%",
          },
        }}
      >
        {userContext.consumerId > 0 ? (
          <Grid>
            <Grid
              className="title-button-responsive"
              item
              xs={6}
              md={3}
              sx={{
                display: "flex !important",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h4 className="page-title">User Groups</h4>
              <Grid
                item
                xs={6}
                md={3}
                marginRight={0}
                textAlign="end"
                paddingTop="0px"
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleAddUserGroup}
                  size="medium"
                  startIcon={<AddIcon />}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
            <Grid className="paper top-space">
              <Paper elevation={4} className="paper-container">
                <Grid container rowGap={1} className="search-space">
                  <Grid item xs={12} sm={6} md={8} lg={8} xl={8}>
                    {matches ? (
                      <TextField
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            searchValue(value);
                          }
                        }}
                        placeholder="Search..."
                        type="text"
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={(e) => setValue(e.target.value)}
                        //onKeyDown={() => searchValue(value)}
                        value={value}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton onClick={() => searchValue("")}>
                                {/* {" "} */}
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          ),

                          endAdornment: value && (
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => searchValue("")}
                            >
                              <ClearIcon />
                            </IconButton>
                          ),
                        }}
                      />
                    ) : (
                      <TextField
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            searchValue(value);
                          }
                        }}
                        placeholder="Search..."
                        type="text"
                        variant="outlined"
                        size="small"
                        onChange={(e) => setValue(e.target.value)}
                        //onKeyDown={() => searchValue(value)}
                        value={value}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton onClick={() => searchValue(value)}>
                                {/* {" "} */}
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          ),

                          endAdornment: value && (
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => searchValue("")}
                            >
                              <ClearIcon />
                            </IconButton>
                          ),
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    <Grid
                      sx={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          cursor: "pointer",
                        }}
                      >
                        {/* <Box>
                          <img src={require("../../Assets/pdf.png")} alt="" />
                        </Box>
                        <Box>
                          <img src={require("../../Assets/xls.png")} alt="" />
                        </Box> */}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <TableContainer>
                  <Table
                    aria-labelledby="tableTitle"
                    size={dense ? "small" : "medium"}
                  >
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleCheckSelectAll}
                      //onRequestSort={handleRequestSort}
                      rowCount={resultData.userGroupCollection.length}
                    />
                    <TableBody>
                      {stableSort(
                        resultData.userGroupCollection,
                        getComparator(order, orderBy)
                      )
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item: any, index: any) => {
                          const isItemSelected = isSelected(item.userGroupId);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow hover key={item.userGroupId}>
                              <TableCell
                                onClick={() =>
                                  item.consumerId && mediumDevice
                                    ? handleEditUserGroup(item.userGroupId)
                                    : null
                                }
                                sx={{
                                  display: {
                                    xs: "table-cell",
                                    sm: "table-cell",
                                    md: "table-cell",
                                    lg: "table-cell",
                                  },
                                }}
                                align="left"
                              >
                                {item.consumerId > 0 ? (
                                  <Button
                                    disableRipple
                                    color="inherit"
                                    onClick={() =>
                                      handleEditUserGroup(item.userGroupId)
                                    }
                                    className="manuButton-link"
                                    sx={{
                                      backgroundColor: "transparent",
                                    }}
                                  >
                                    <span className="text-link">
                                      {item.code ? item.code + " - " : ""}
                                      <span>{item.name}</span>
                                    </span>
                                  </Button>
                                ) : (
                                  <Button
                                    disableRipple
                                    color="inherit"
                                    className="init-button-admin"
                                    sx={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                      cursor: "default",
                                      backgroundColor: "transparent",
                                    }}
                                  >
                                    {item.code === ""
                                      ? item.name
                                      : item.code + " - " + item.name}
                                  </Button>
                                )}
                              </TableCell>
                              <TableCell
                                sx={{
                                  display: {
                                    xs: "table-cell",
                                    sm: "table-cell",
                                    md: "table-cell",
                                    lg: "table-cell",
                                  },
                                }}
                                align="center"
                                height={55}
                              >
                                {item.consumerId > 0 ? (
                                  <Tooltip title="More" placement="top">
                                    <IconButton
                                      onClick={(e: any) =>
                                        handleOpenMore(
                                          e,
                                          item.userGroupId,
                                          item
                                        )
                                      }
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <></>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {resultData.userGroupCollection.length <= 0 ? (
                        <TableRow hover tabIndex={-1} key={"0"}>
                          <TableCell colSpan={6} align="center">
                            No record(s) found
                          </TableCell>
                        </TableRow>
                      ) : (
                        <></>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>

                {resultData.userGroupCollection.length > 0 && (
                  <Grid
                    container
                    paddingTop={1}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Grid item xs={12} sm={6} className="pagination-label">
                      {pageStartIndex} - {pageEndIndex} of {totalCount}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack
                        component="div"
                        alignItems={mobileScreen ? " center" : "end"}
                        justifyContent={"center"}
                      >
                        <Pagination
                          color="primary"
                          count={counData}
                          page={page_Index}
                          onChange={handleChangePageNew}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                )}
              </Paper>
            </Grid>
          </Grid>
        ) : (
          <MissingConsumer consumerId={userContext.consumerId} />
        )}
      </Box>
      {/* Action popover - starts */}
      <Popover
        elevation={1}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseMore}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleEditUserGroup(userGroupId)}>
              <ListItemText primary="Edit" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              className="delete-container"
              // onClick={handleOpenDeleteUserGroupListModal}
              onClick={() => openUserGroupList(userGroupId)}
            >
              Delete
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>

      <Dialog
        open={openListOfDeletePersonModal}
        onClose={handleCloseDeleteUserGroupModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="user-group-member-dialog-root"
      >
        <Formik
          initialValues={submitData}
          validationSchema={validateSchema}
          enableReinitialize
          onSubmit={(values) => {
            submitForm(values); // Call submitForm with all values
          }}
        >
          {(props) => (
            <Form>
              <DialogContent className="user-group-member-dialogcontent">
                <DialogContentText id="alert-dialog-description">
                  WARNING! The Group <b>{userGroupName}</b> consists of the
                  following members. If you want to delete, you need to move to
                  another group.
                  <Grid container rowGap={1} mt={2} className="search-space">
                    <Grid item sm={5} md={5}>
                      <Typography>
                        Move all users to <b>{userGroupName}</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={5} md={5}>
                      <AsyncCustomSelect
                        menuPortalTarget={document.querySelector("body")}
                        placeholder={"User Group"}
                        defaultOptions={resultData?.userGroupCollection
                          ?.filter(
                            (val) =>
                              val.consumerId !== 0 &&
                              val.userGroupId !== userGroupId
                          )
                          ?.map((val) => ({
                            ...val,
                            value: val.userGroupId,
                            label: val.label,
                          }))}
                        handleChange={(e) => handleDropdownChange(e, props)}
                        id={"initUserGroupId"}
                        FieldName={"initUserGroupId"}
                        options={resultData?.userGroupCollection
                          ?.filter(
                            (val) =>
                              val.consumerId !== 0 &&
                              val.userGroupId !== userGroupId
                          )
                          ?.map((val) => ({
                            ...val,
                            value: val.userGroupId,
                            label: val.label,
                          }))}
                        isClearable
                        isSearchable
                        isNotCreatable={true}
                        Value={props?.values?.initUserGroupId}
                      />

                      <Typography
                        className={
                          props.values.initUserGroupId === undefined ||
                          props.values.initUserGroupId === null ||
                          props.values.initUserGroupId < 1
                            ? "validation-message"
                            : "input"
                        }
                      >
                        {props.values.initUserGroupId === undefined ||
                          props.values.initUserGroupId === null ||
                          (props.values.initUserGroupId < 1 &&
                            "Please select a group.")}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container rowGap={1} pt={2} className="search-space">
                    <List component="div" disablePadding sx={{ width: "100%" }}>
                      <Box>
                        <ListItemButton
                          sx={{
                            borderTop: "1px solid #e9e9ef",
                            "&:hover": { backgroundColor: "#f1f6fe" },
                            height: "40px",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Checkbox
                              id="selectAll"
                              checked={selectAll}
                              onChange={handleSelectAll}
                            />
                            <Typography sx={{ fontWeight: 700 }}>
                              All
                            </Typography>
                          </Box>
                        </ListItemButton>
                      </Box>
                      {userGroupMemberList !== undefined &&
                        userGroupMemberList.map((item: any, mainIndex) => (
                          <Grid
                            container
                            key={mainIndex}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Grid item sm={5} md={5}>
                              <ListItemButton
                                sx={{
                                  borderTop: "1px solid #e9e9ef",
                                  "&:hover": { backgroundColor: "#f1f6fe" },
                                  height: "40px",
                                }}
                              >
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <Checkbox
                                    id={item.invitedEmailId}
                                    checked={item.isDeleted}
                                    onChange={() =>
                                      handleCheckboxChange(mainIndex)
                                    }
                                  />
                                  <Typography sx={{ fontWeight: 700 }}>
                                    {item.invitedEmailId}
                                  </Typography>
                                </Box>
                              </ListItemButton>
                            </Grid>
                            <Grid item sm={4} md={4}>
                              <AsyncCustomSelect
                                id={"userGroupId"}
                                FieldName={"userGroupId"}
                                menuPortalTarget={document.querySelector(
                                  "body"
                                )}
                                defaultOptions={resultData?.userGroupCollection
                                  ?.filter(
                                    (val) =>
                                      val.consumerId !== 0 &&
                                      val.userGroupId !== userGroupId
                                  )
                                  ?.map((val) => ({
                                    ...val,
                                    value: val.userGroupId,
                                    label: val.label,
                                  }))}
                                options={resultData?.userGroupCollection
                                  ?.filter(
                                    (val) =>
                                      val.consumerId !== 0 &&
                                      val.userGroupId !== userGroupId
                                  )
                                  ?.map((val) => ({
                                    ...val,
                                    value: val.userGroupId,
                                    label: val.label,
                                  }))}
                                handleChange={(selectedOption: any) => {
                                  props.setFieldValue(
                                    `updatedGroupData[${mainIndex}].userGroupId`,
                                    selectedOption.value
                                  );
                                  props.setFieldValue(
                                    `updatedGroupData[${mainIndex}].label`,
                                    selectedOption.label
                                  );
                                  props.setFieldValue(
                                    `updatedGroupData[${mainIndex}].value`,
                                    item.invitationId
                                  );
                                }}
                                Value={
                                  props.values.updatedGroupData[mainIndex]
                                    ?.userGroupId
                                }
                              />
                            </Grid>
                          </Grid>
                        ))}
                    </List>
                  </Grid>
                </DialogContentText>
              </DialogContent>
              <DialogActions
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  type="submit" // This triggers Formik's handleSubmit
                  className="button-delete"
                >
                  Yes
                </Button>
                <Button onClick={handleCloseDeleteUserGroupModal} autoFocus>
                  No
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>

      <Dialog
        open={openDeleteModal}
        onClose={handleCloseDeleteUserGroupModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this User Group?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleDeleteUserGroup(userGroupId)}
            className="button-delete"
          >
            Yes
          </Button>
          <Button onClick={handleCloseDeleteUserGroupModal} autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
      {/* Delete modal popup - ends */}
    </>
  );
}
