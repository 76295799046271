import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Box, Button } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import dayjs from 'dayjs';
import { Divider } from "@mui/material";

const PopupBody = styled('div')(
    ({ theme }) => `
  width: max-content;
  margin-top: 8px;
  border-radius: 8px;
  border: 1px solid ${theme.palette.mode === 'dark' ? ' #434D5B' : '#DAE2ED'};
  background-color: ${theme.palette.mode === 'dark' ? ' #1C2025' : '#fff'};
  box-shadow: ${theme.palette.mode === 'dark'
            ? `0px 4px 8px rgb(0 0 0 / 0.7)`
            : `0px 4px 8px rgb(0 0 0 / 0.1)`
        };
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  z-index: 1000;
  color:black;
`,
);

interface DateRangepickerProps {
    fromDate: string;
    setFromDate: (value: dayjs.Dayjs | null) => void;
    setToDate: (value: dayjs.Dayjs | null) => void;
    toDate: string;
    disabled?: boolean;
    name?: string;
    sx?: any;
    minDate?: any;
    maxDate?: any;
    readonly?: boolean;
    className?: string;
}

const CustomDateRangePicker = (props: DateRangepickerProps) => {
    const { fromDate, setFromDate, toDate, setToDate, minDate, maxDate, ...restProps } = props;
    const [error, setError] = useState('');
    const [open, setOpen] = useState(false);
    const [anchor, setAnchor] = useState(null);
    const [tempFromDate, setTempFromDate] = useState(dayjs(fromDate));
    const [tempToDate, setTempToDate] = useState(dayjs(toDate));

    const formatDate = (date) => {
        return dayjs(date).format('DD-MM-YYYY');
    };

    const handleDateRange = () => {
        if (!tempFromDate || !tempToDate) {
            setError('Please select both "from" and "to" dates.');
            return;
        }
        setFromDate(tempFromDate);
        setToDate(tempToDate);
        setOpen(false);
        setError('');
    };

    const cancelDateRange = () => {
        setOpen(false);
        setError('');
    };

    const openCalenderPop = (event) => {
        setAnchor(anchor ? null : event.currentTarget);
        setOpen(!open);
        event.stopPropagation();
    }

    const id = open ? 'simple-popup' : undefined;

    useEffect(()=>{
        setTempFromDate(dayjs(fromDate))
    },[fromDate]);

    useEffect(()=>{
        setTempToDate(dayjs(toDate))
    },[toDate])

    return (
        <>
            <Button
                {...restProps}
                variant="outlined"
                onClick={(e) => openCalenderPop(e)}
            >
                <span> {`${formatDate(fromDate)} To ${formatDate(toDate)}`}</span>
                <CalendarMonthIcon />
            </Button>
            <BasePopup open={open} id={id} anchor={anchor} style={{ zIndex: 1 }} placement='bottom-end'>
                <PopupBody>
                    <Box>
                        <Box style={{ padding: '15px' }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <DesktopDatePicker
                                        label="From"
                                        format="DD-MM-YYYY"
                                        maxDate={maxDate}
                                        minDate={minDate}
                                        value={tempFromDate}
                                        slotProps={{ textField: { size: "small" } }}
                                        sx={{ width: '140px' }}
                                        onChange={(newValue) => setTempFromDate(dayjs(newValue))}
                                    />
                                    <DesktopDatePicker
                                        format="DD-MM-YYYY"
                                        sx={{ width: '140px' }}
                                        maxDate={maxDate}
                                        minDate={minDate}
                                        slotProps={{ textField: { size: "small" } }}
                                        label="To"
                                        value={tempToDate}
                                        onChange={(newValue) => setTempToDate(dayjs(newValue))}
                                    />
                                </Box>
                            </LocalizationProvider>
                        </Box>
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                        <Divider />
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                gap: 1,
                                padding: '15px'
                            }}
                        >
                            <Button
                                variant="outlined"
                                onClick={handleDateRange}
                                sx={{
                                    backgroundColor: '#0b0b45',
                                    color: 'white',
                                    '&:hover': {
                                        backgroundColor: '#0b0b45',
                                    }
                                }}
                            >
                                Apply
                            </Button>
                            <Button variant="outlined" onClick={cancelDateRange}>
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                </PopupBody>
            </BasePopup>
        </>
    );
};

export default CustomDateRangePicker;
