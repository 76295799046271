import React, { useContext } from "react";
import { useEffect, useState } from "react";
import Title from "../../Components/Title";
import MissingConsumer from "../../Components/MissingConsumer";
import * as Yup from "yup";
import { GetMenuAccess, GetUserGroupList, saveUserMenuAccessData, UserGroupMenuAccess, } from "../../Services/UserGroupAccessService";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Grid,
  Backdrop,
  ListItemButton,
  List,
  Checkbox,
  Collapse,
  Typography
} from "@mui/material";
import { PreferencesContext } from "../../PreferencesContext";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import useMediaQuery from "@mui/material/useMediaQuery";
import SplashComponent from "../../Navigation/splash";
import AsyncSelect from "react-select/async";
import { Form, Formik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import { AlertType } from "../../shared/Modal/AlertType";
const UserGroupAccessMenu: any = {
  userGroupAccessCollection: [],
};

export default function UserGroupAccess() {
  const initialUserGroupAccess: IUserGroupAccess = {
    code: "",
    displayOrder: 0,
    menuId: "",
    menuUrl: "",
    name: "",
    location: "",
    parentMenuCode: "",
    hasInsertAccess: false,
    hasUpdateAccess: false,
    hasViewAccess: false,
    hasImportAccess: false,
    hasExportAccess: false,
    hasDeleteAccess: false,
  };

  const { userContext, updateUserContext } = useContext(PreferencesContext);

  const phone = useMediaQuery("(max-width:799px)");
  const phone2 = useMediaQuery("(max-width:499px)");
  // const [opne,setOpen] = useState(false)
  const [isLoading, setLoading] = useState(false);
  const [resultData, setResultData] = useState(initialUserGroupAccess);

  const [parent, setParent] = useState();
  const [openCollapse, setOpenCollapse] = useState({});
  const [parentChecked, setParentChecked] = useState(false);
  const [icon, setIcon] = useState({});
  const [userGroupList, serUserGroupList] = useState([]);
  const [userGroup, setUserGroup] = useState({ id: 0, label: '' });
  const [isSubmitLoading, setSubmitLoading] = useState(false);
  const [children, setChildren] = useState([
    {
      value: "suppliers",
      label: "Suppliers",
      checked: false,
      add: false,
      update: false,
      delete: false,
      import: false,
      export: false,
    },
    {
      value: "buyers",
      label: "Buyers",
      checked: false,
      add: false,
      update: false,
      delete: false,
      import: false,
      export: false,
    },
    {
      value: "productCategory",
      label: "Product Category",
      checked: false,
      add: false,
      update: false,
      delete: false,
      import: false,
      export: false,
    },
    {
      value: "products",
      label: "Products",
      checked: false,
      add: false,
      update: false,
      delete: false,
      import: false,
      export: false,
    },
    {
      value: "taxes",
      label: "Taxes",
      checked: false,
      add: false,
      update: false,
      delete: false,
      import: false,
      export: false,
    },
    {
      value: "units",
      label: "Units",
      checked: false,
      add: false,
      update: false,
      delete: false,
      import: false,
      export: false,
    },
    {
      value: "transporters",
      label: "Transporters",
      checked: false,
      add: false,
      update: false,
      delete: false,
      import: false,
      export: false,
    },
    {
      value: "warehouses",
      label: "Warehouses",
      checked: false,
      add: false,
      update: false,
      delete: false,
      import: false,
      export: false,
    },
    {
      value: "areas",
      label: "Areas",
      checked: false,
      add: false,
      update: false,
      delete: false,
      import: false,
      export: false,
    },
  ]);
  const [isAllCollapsOpen, setIsAllCollapsOpen] = useState(true);
  const [readWrite, setReadWrite] = useState(Array(10).fill(false));
  const [fullControl, setFullControl] = useState(Array(10).fill(false));
  const [viewAccess, setViewAccess] = useState(Array(10).fill(false));
  const [updateProps, setUpdateProps] = useState<any>([]);
  const [userGroupAccessData, setUserGroupAccessData] = useState({});

  const userAccessValidationSchema = Yup.array().of(
    Yup.object().shape({
      menuId: Yup.number(),
      name: Yup.string(),
      menuUrl: Yup.string(),
      parentMenu: Yup.string(),
      hasDeleteAccess: Yup.boolean(),
      hasInsertAccess: Yup.boolean(),
      hasImportAccess: Yup.boolean(),
      hasExportAccess: Yup.boolean(),
      hasUpdateAccess: Yup.boolean(),
      hasViewAccess: Yup.boolean(),
    })
  );

  const [updateData, setUpdateData] = useState({
    ...initialUserGroupAccess
  });

  interface IUserGroupAccess {
    code: string;
    displayOrder: number;
    menuId: string;
    menuUrl: string;
    name: string;
    location: string;
    parentMenuCode: string;
    hasInsertAccess: boolean;
    hasUpdateAccess: boolean;
    hasViewAccess: boolean;
    hasImportAccess: boolean;
    hasExportAccess: boolean;
    hasDeleteAccess: boolean;
  }

  const usersecondSchema = Yup.array().of(
    Yup.object().shape({
      menuId: Yup.string(),
      name: Yup.string(),
      menuUrl: Yup.string(),
      parentMenu: Yup.string(),

      child: Yup.array().of(
        Yup.object().shape({
          menuId: Yup.number(),
          name: Yup.string(),
          menuUrl: Yup.string(),
          parentMenu: Yup.string(),
          hasDeleteAccess: Yup.boolean(),
          hasInsertAccess: Yup.boolean(),
          hasImportAccess: Yup.boolean(),
          hasExportAccess: Yup.boolean(),
          hasUpdateAccess: Yup.boolean(),
          hasViewAccess: Yup.boolean(),
        })
      ),
    })
  );

  const handleParantChange = (e: any, parentData: any) => {
    const isChecked = e.target.checked;
    setParentChecked(isChecked);

    const updatedChild = resultData?.filter(
      (childData: any) =>
        childData.parentMenuCode !== "" &&
        parentData.code === childData.parentMenuCode
    );
    // const updatedChild2 = updatedChild.filter((key) => key == "hasDeleteAccess" && key == "hasExportAccess" && key == "hasImportccess" && key == "hasInsertAccess" && key == "hasUpdateAccess" && key == "hasViewAccess")

    const updatedChild2 = updatedChild.map((obj) => {
      return Object.fromEntries(
        Object.entries(obj).filter(
          ([key, value]) => typeof value === "boolean" || key === "displayOrder"
        )
      );
    });

    // const updatedChild2 = updatedChild.filter((item) => console.log(item))
    // console.log("updatedChild2", updatedChild2);

    // const updatedChild = children.map((item) => ({
    //   ...item,
    //   checked: isChecked,
    //   add: isChecked,
    //   update: isChecked,
    //   delete: isChecked,
    //   import: isChecked,
    //   export: isChecked,
    // }));
    // setChildren(updatedChild);
  };

  const handleAllSelect = () => {
    const allOpenCollapses = {};

    for (let i = 0; i < 10; i++) {
      allOpenCollapses[i] = isAllCollapsOpen;
    }
    setIsAllCollapsOpen(!isAllCollapsOpen);
    setOpenCollapse(allOpenCollapses);
  };

  const handleChildchange = (e, index) => {
    const ischecked = e.target.checked;
    const updatechildren = [...children];
    updatechildren[index].checked = ischecked;
    setChildren(updatechildren);

    setParentChecked(updatechildren.every((child) => child.checked));

    const child = updatechildren[index];
    const value = child.checked;
    const allTrue = Object.keys(child)
      .filter((key) => key !== "value" && key !== "label" && key !== "checked")
      .forEach((key) => (child[key] = value));

    setChildren(updatechildren);
  };

  const handlePropsChange = (
    e,
    index,
    items: string,
    parentMenuCode,
    parentMainIndex
  ) => {
    const ischecked = e.target.checked;
    const updatedItem = [...resultData];
    updatedItem[index][items] = ischecked;
    // setResultData(updatedItem);

    if (updatedItem[index][items] === true) {
      updatedItem[index].hasViewAccess = true;
      // setResultData(updatedItem)
    }
    if (!updatedItem[index].hasViewAccess) {
      [
        "hasDeleteAccess",
        "hasExportAccess",
        "hasImportAccess",
        "hasInsertAccess",
        "hasUpdateAccess",
      ].forEach((access) => {
        updatedItem[index][access] = false;
      });
    }
    setResultData(updatedItem);

    const filterChildMenu = resultData.filter(
      (item) => item.parentMenuCode === parentMenuCode
    );
    //set the value of true if all view are true (ViewAcces)
    const isViewAccessAlltrue = filterChildMenu.every(
      (item) => item.hasViewAccess === true
    );
    const tempViewAccess = [...viewAccess];
    tempViewAccess[parentMainIndex] = isViewAccessAlltrue;
    setViewAccess(tempViewAccess);

    //set the value of true if all view are true (Read Write)
    const isReadWriteAllTrue = filterChildMenu.every(
      (item) =>
        item.hasViewAccess === true &&
        item.hasInsertAccess === true &&
        item.hasUpdateAccess === true
    );
    const tempReadWrite = [...readWrite];
    tempReadWrite[parentMainIndex] = isReadWriteAllTrue;
    setReadWrite(tempReadWrite);

    //set the value of true if all view are true (fullcontroll)
    const isFullControlAllTrue = filterChildMenu.every(
      (item) =>
        item.hasViewAccess === true &&
        item.hasInsertAccess === true &&
        item.hasUpdateAccess === true &&
        item.hasDeleteAccess === true &&
        item.hasImportAccess === true &&
        item.hasExportAccess === true
    );

    const tempfullControl = [...fullControl];
    tempfullControl[parentMainIndex] = isFullControlAllTrue;
    setFullControl(tempfullControl);
  };

  function handleViewAccessOnly(event: any, code: string, mainIndex) {

    const ischecked = event.target.checked;
    const updatedItem = [...resultData];

    //for the changing value of the ViewAccess CheckBox
    const ViewcontrollCheckBox = [...viewAccess];
    ViewcontrollCheckBox[mainIndex] = !ViewcontrollCheckBox[mainIndex];
    setViewAccess(ViewcontrollCheckBox);

    // let filterdata = resultData.filter(
    //   (item, index) =>
    //     item.parentMenuCode === code &&
    //     (updatedItem[index].hasViewAccess = ischecked)
    // );

    let filterdata = resultData.filter(
      (item: any, index: any) =>
        (item.code === code || item.parentMenuCode === code) &&
        ((updatedItem[index].hasViewAccess = ischecked))
    );
    setResultData(updatedItem);
  }

  function handleFullControl(event: any, code: string, mainIndex) {
    //for the changing value of the FullControll CheckBox
    const fullcontrolCheckBox = [...fullControl];
    fullcontrolCheckBox[mainIndex] = !fullcontrolCheckBox[mainIndex];
    setFullControl(fullcontrolCheckBox);

    //for the changing value of the ViewAccess CheckBox
    // const ViewcontrollCheckBox = [...viewAccess];
    // ViewcontrollCheckBox[mainIndex] = !ViewcontrollCheckBox[mainIndex];
    // setViewAccess(ViewcontrollCheckBox);

    const newCheckboxes = [...readWrite];
    newCheckboxes[mainIndex] = newCheckboxes[mainIndex]
      ? newCheckboxes[mainIndex]
      : !newCheckboxes[mainIndex];

    if (newCheckboxes[mainIndex] && !fullcontrolCheckBox[mainIndex]) {
      newCheckboxes[mainIndex] = !newCheckboxes[mainIndex];
    }
    setReadWrite(newCheckboxes);
    setViewAccess(newCheckboxes); //for the changing value of the ViewAccess CheckBox\
    setFullControl(newCheckboxes);

    const ischecked = event.target.checked;
    const updatedItem = [...resultData];

    let filtrateData = resultData.filter(
      (item: any, index: any) =>
        (item.code === code || item.parentMenuCode === code) &&
        ((updatedItem[index].hasImportAccess = ischecked),
          (updatedItem[index].hasDeleteAccess = ischecked),
          (updatedItem[index].hasExportAccess = ischecked),
          (updatedItem[index].hasInsertAccess = ischecked),
          (updatedItem[index].hasUpdateAccess = ischecked),
          (updatedItem[index].hasViewAccess = ischecked))
    );
    setResultData(updatedItem);
  }

  function handleReadWriteOnly(event: any, code: string, mainIndex) {
    const fullcontrolCheckBox = [...fullControl];
    fullcontrolCheckBox[mainIndex] =
      fullcontrolCheckBox[mainIndex] && !fullcontrolCheckBox[mainIndex];
    setFullControl(fullcontrolCheckBox);

    //for the changing value of the ReadWrite CheckBox
    const newCheckboxes = [...readWrite];
    newCheckboxes[mainIndex] = !newCheckboxes[mainIndex];

    setReadWrite(newCheckboxes);
    setViewAccess(newCheckboxes);

    //for the changing value of the ViewAccess CheckBox
    // const ViewcontrollCheckBox = [...viewAccess];
    // ViewcontrollCheckBox[mainIndex] = !ViewcontrollCheckBox[mainIndex];
    // setViewAccess(ViewcontrollCheckBox);

    //chane the all Child value
    const ischecked = event.target.checked;
    const updatedItem = [...resultData];

    // let filterdata = resultData.filter(
    //   (item, index) =>
    //     item.parentMenuCode === code &&
    //     ((updatedItem[index].hasInsertAccess = ischecked),
    //       (updatedItem[index].hasUpdateAccess = ischecked),
    //       (updatedItem[index].hasViewAccess = ischecked),
    //       (updatedItem[index].hasDeleteAccess = false), //false the others item like delete
    //       (updatedItem[index].hasImportccess = false), //false the others item like Import
    //       (updatedItem[index].hasExportAccess = false)) //false the others item like Export
    // );

    let filterdata = resultData.filter(
      (item: any, index: any) =>
        (item.code === code || item.parentMenuCode === code) &&
        ((updatedItem[index].hasInsertAccess = ischecked),
          (updatedItem[index].hasUpdateAccess = ischecked),
          (updatedItem[index].hasViewAccess = ischecked),
          (updatedItem[index].hasDeleteAccess = false), //false the others item like delete
          (updatedItem[index].hasImportAccess = false), //false the others item like Import
          (updatedItem[index].hasExportAccess = false)) //false the others item like Export
    );
    setResultData(updatedItem);
  }

  const handleToggleCollapse = (mainIndex: any) => {
    //collaps
    setOpenCollapse((prestate) => ({
      ...prestate,
      [mainIndex]: !prestate[mainIndex],
    }));

    //change icons accroding the collaps
    setIcon((prestate) => ({
      ...prestate,
      [mainIndex]: !prestate[mainIndex],
    }));
  };

  // const FetchUserGroupMenu = async () => {
  //   try {
  //     setLoading(true);

  //     let param = new URLSearchParams();

  //     param.append("consumerId", userContext.consumerId.toString());
  //     param.append("firmId", userContext.firmId.toString());
  //     param.append("branchId", userContext.branchId.toString());
  //     param.append("userKey", userContext.userKey.toString());

  //     const result: any = await GetUserAccess(param);

  //     if (result) {
  //       let userGroupAccessMenu = result.data.data.userGroupAccessCollection;

  //       setResultData(userGroupAccessMenu);

  //       const ParantMenu = userGroupAccessMenu.filter(
  //         (item) => item.parentMenuCode === ""
  //       );
  //       setParent(ParantMenu);

  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //   }
  // };

  async function FetchGetMenuAccess() {
    try {
      let params = new URLSearchParams();

      params.append("consumerId", "1");
      params.append("firmId", "1");

      const result: any = await GetMenuAccess(params);
      if (result) {
        let menuAccessCollection = result.data.data.getMenuAccessCollection;

        setResultData(menuAccessCollection);

        const ParantMenu = menuAccessCollection.filter(
          (item: any) => item.parentMenuCode === ""
        );
        setParent(ParantMenu);
      }
    } catch (error) { }
  }

  async function FetchUserGroupMenuAccessList() {
    try {
      let params = new URLSearchParams();
      params.append("consumerId", userContext.consumerId.toString());
      params.append("firmId", userContext.firmId.toString());
      params.append("branchId", userContext.branchId.toString());
      params.append('userGroupId', userGroup.id.toString());

      const result: any = await UserGroupMenuAccess(params);
      if (result !== undefined && result !== null && result.data !== undefined && result.data.data !== undefined && result.data.data.getMenuAccessCollection !== undefined && result.data.data.getMenuAccessCollection !== null) {
        let menuAccessCollection = result.data.data.getMenuAccessCollection;
        setResultData(menuAccessCollection);

        const ParantMenu = menuAccessCollection.filter(
          (item: any) => item.parentMenuCode === ""
        );
        setParent(ParantMenu);
        // const updatedItem = [...menuAccessCollection];

        // let filterdata = menuAccessCollection.filter(
        //   (item: any, index: any) =>
        //   ((updatedItem[index].hasInsertAccess = item.hasInsertAccess),
        //     (updatedItem[index].hasUpdateAccess = item.hasUpdateAccess),
        //     (updatedItem[index].hasViewAccess = item.hasViewAccess),
        //     (updatedItem[index].hasDeleteAccess = item.hasDeleteAccess), //false the others item like delete
        //     (updatedItem[index].hasImportAccess = item.hasImportAccess), //false the others item like Import
        //     (updatedItem[index].hasExportAccess = item.hasExportAccess)) //false the others item like Export
        // );
        // setResultData(filterdata);


      }
    } catch (e: any) {
      // updateUserContext({
      //   ...userContext,
      //   isAlert: true,
      //   alertMessage:
      //     typeof e === "string" ? (e as string) : (e?.message as string),
      //   alertType: AlertType.Error,
      // });
    }
  }

  const handlePropsUpdate = (
    dorder: any,
    mId: any,
    ky: any,
    e: any,
    parentMenuCode: any,
    code: any,
    childName: any //child name
  ) => {
    // updateProps.map((item: any, index) => {
    //   if (item.menuId == mId && item.key == ky) {
    //     setUpdateProps((prevItems) => {
    //       if (index !== -1) {
    //         return [
    //           ...prevItems.slice(0, index),
    //           ...prevItems.slice(index + 1),
    //         ];
    //       }
    //     });
    //   }
    // });

    setUpdateProps((preState) => {
      // const parentIndex = preState.findIndex((p) => p.parent == code)
      // if(parentIndex !== -1){
      //   alert();
      //   return [{ parent: code, child: [childName] }];
      // }
      // else{
      //   return [...preState,{ parent: code, child: [childName] }];
      // }
      // if (preState.length == 0) {
      //   alert();
      //   return [{ parent: code, child: [childName] }];
      // }
      //  else {
      //   preState.map((item, index) => {
      //     if (item.parent == code) {
      //       const indexItem = { ...preState[index] };
      //       indexItem.child.push(childName);
      //     }
      //     else{
      //       return [{ parent: code, child: [childName] }];
      //     }
      //   });
      //   // preState.push({ parent: code, child: childName });
      //   return preState;
      // }
    });

    // updateProps.push({

    // })

    // updateProps.push({
    //   displayOrder: dorder,
    //   menuId: mId,
    //   key: ky,
    //   value: e.target.checked,
    // });
  };

  const handleChangeUserGroup = (object: any) => {
    // FetchUserGroupMenu();
    setUserGroup({ id: object.userGroupId, label: object.label });
    FetchUserGroupList();
  };

  async function FetchUserGroupList() {
    setLoading(true);
    try {
      let params = new URLSearchParams();
      params.append("consumerId", userContext.consumerId.toString());
      params.append("firmId", userContext.firmId.toString());
      params.append("branchId", userContext.branchId.toString());

      const result: any = await GetUserGroupList(params);

      if (result && result.data.data) {
        serUserGroupList(result.data.data.userGroupAccessCollection);
      }
      setLoading(false);
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage:
          typeof e === "string" ? (e as string) : (e?.message as string),
        alertType: AlertType.Error,
      });
    }
    setLoading(false)
  }

  async function SubmitUserAccess(value: any) {
    setSubmitLoading(true);
    const menuAccessData = value;

    const formData = new FormData();
    formData.append('consumerId', userContext.consumerId.toString());
    formData.append('firmId', userContext.firmId.toString());
    formData.append('branchId', userContext.branchId.toString());
    formData.append('userGroupId', userGroup.id.toString());
    formData.append('dataManagedBy', userContext.userId.toString());
    formData.append('menuAccessData', JSON.stringify(menuAccessData));
    try {
      // const resultVaccineSave: any = await CreateVaccine(formData);
      const userAccess: any = await saveUserMenuAccessData(formData);

      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: userAccess.message,
        alertType: AlertType.Success,
      });
      setSubmitLoading(false);
    }
    catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage:
          typeof e === "string" ? (e as string) : (e?.message as string),
        alertType: AlertType.Error,
      });
    }
  }

  useEffect(() => {
    FetchUserGroupList();
    // FetchGetMenuAccess();
  }, []);

  useEffect(() => {
    if (userGroup.id > 0) {
      FetchUserGroupMenuAccessList();
    }
  }, [userGroup.id])

  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          background: "#fff",
        }}
        open={isLoading}>
        <SplashComponent />
      </Backdrop>
      <Title pageTitle="User Group Access" />
      <Formik
        enableReinitialize
        initialValues={resultData}
        validationSchema={userAccessValidationSchema}
        onSubmit={(values) => {
          SubmitUserAccess(values);
        }}>
        {({ values, setFieldValue }) => (
          <Form>
            <Box
              className="boxContainer"
              sx={{
                "& > :not(style)": {
                  width: "100%",
                },
              }}>
              {userContext.consumerId > 0 ? (
                <Grid>
                  <Grid
                    className="title-button-responsive"
                    item
                    xs={6}
                    md={3}
                    sx={{
                      display: "flex !important",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}>
                    <h4 className="page-title">
                      User Group Access{" "}
                      {userGroup !== undefined && userGroup.label !== '' && "-" + userGroup.label.toUpperCase()}
                    </h4>

                    <Grid
                      item
                      xs={6}
                      md={3}
                      marginRight={0}
                      textAlign="end"
                      paddingTop="0px"></Grid>
                  </Grid>
                  <Grid className="paper top-space">
                    <Paper elevation={4} className="group-container">
                      <Grid
                        container
                        rowGap={1}
                        sx={{
                          marginBottom: "10px",
                          justifyContent: "space-between",
                        }}>
                        {/* <h5>User Group</h5> */}
                        <AsyncSelect
                          placeholder={"User Group"}
                          defaultOptions={userGroupList.map((vall: any) => {
                            return { ...vall, value: vall.label };
                          })}
                          styles={customStyles}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: "#0b0b45",
                              primary25: "#ecebef",
                              primary50: "#e1dfea",
                            },
                          })}
                          onChange={(e: any) => {
                            handleChangeUserGroup(e);
                          }}
                        />

                        {userGroup.id > 0 ?
                          <Button variant="outlined" onClick={handleAllSelect}>
                            {isAllCollapsOpen ? "Expand" : "collapse"}
                          </Button> : <></>}
                      </Grid>

                      <Grid container rowGap={1} className="search-space">
                        {userGroup.id > 0 ?
                          <List
                            component="div"
                            disablePadding
                            sx={{ width: "100%" }}>
                            {parent !== undefined &&
                              parent.map((item: any, mainIndex: any) => (

                                <>
                                  <Box key={mainIndex}>
                                    <ListItemButton
                                      sx={{
                                        borderTop: "1px solid #e9e9ef",
                                        "&:hover": {
                                          backgroundColor: "#f1f6fe",
                                        },
                                        height: "40px",
                                      }}>
                                      {resultData.some(
                                        (value) =>
                                          item.displayOrder + 0.1 ===
                                          value.displayOrder
                                      ) ? (
                                        <>
                                          {icon[mainIndex] ||
                                            !isAllCollapsOpen ? (
                                            <ExpandLess
                                              onClick={() =>
                                                handleToggleCollapse(mainIndex)
                                              }
                                            />
                                          ) : (
                                            <ExpandMore
                                              onClick={() =>
                                                handleToggleCollapse(mainIndex)
                                              }
                                            />
                                          )}
                                        </>
                                      ) : (
                                        <Box width={"25px"}></Box>
                                      )}

                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          width: !phone ? "200px" : "150px",
                                        }}>
                                        <Checkbox
                                          // checked={viewAccess[mainIndex]}
                                          checked={item.hasViewAccess}
                                          name={`menus.${mainIndex}.hasViewAccess`}
                                          onChange={(e) => {
                                            handleViewAccessOnly(
                                              e,
                                              item.code,
                                              mainIndex
                                            );
                                          }}
                                        />
                                        <Typography sx={{ fontWeight: 700 }}>
                                          {item.name}
                                        </Typography>
                                      </Box>

                                      {item.code === 'CLIENT_ADMIN_DASHBOARD' ? <></> :
                                        <>
                                          <Box sx={{ width: "100px" }}>
                                            <Typography>
                                              {fullControl[mainIndex]
                                                ? "Full Control"
                                                : readWrite[mainIndex]
                                                  ? "Read Write"
                                                  : null}{" "}
                                            </Typography>
                                          </Box>
                                          {item.code === 'REPORT' ? <></> :
                                            <Box
                                              sx={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}>
                                              <Checkbox
                                                // checked={readWrite[mainIndex]}
                                                checked={item.hasInsertAccess}
                                                onChange={(e) => {
                                                  handleReadWriteOnly(
                                                    e,
                                                    item.code,
                                                    mainIndex
                                                  );
                                                }}
                                              />
                                              <Typography sx={{ fontWeight: 700 }}>
                                                Read Write
                                              </Typography>
                                            </Box>
                                          }
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}>
                                            <Checkbox
                                              // checked={fullControl[mainIndex]}
                                              checked={item.hasExportAccess}
                                              onChange={(e) => {
                                                handleFullControl(
                                                  e,
                                                  item.code,
                                                  mainIndex
                                                );
                                              }}
                                            />
                                            <Typography sx={{ fontWeight: 700 }}>
                                              Full Control
                                            </Typography>
                                          </Box>
                                        </>}
                                    </ListItemButton>
                                  </Box>
                                  {values.map(
                                    (child: any, index: any) =>
                                      item.code === child.parentMenuCode && (
                                        <Collapse
                                          in={openCollapse[mainIndex]}
                                          timeout="auto"
                                          unmountOnExit
                                          sx={{
                                            marginLeft: !phone && "50px",
                                          }}>
                                          <TableContainer>
                                            <Table
                                              aria-labelledby="tableTitle"
                                              sx={{
                                                "& .MuiTableCell-sizeMedium": {
                                                  padding: "0px 0px !important",
                                                },
                                              }}>
                                              <>
                                                {child.displayOrder ===
                                                  item.displayOrder + 0.1 && (
                                                    child.parentMenuCode !== 'REPORT' ?
                                                      <TableHead>
                                                        <TableCell width="15%">
                                                          <Typography
                                                            sx={{ fontWeight: 600 }}
                                                          >
                                                            Menu
                                                          </Typography>
                                                        </TableCell>
                                                        <TableCell width="15%">
                                                          <Typography
                                                            sx={{ fontWeight: 600 }}
                                                          >
                                                            View
                                                          </Typography>
                                                        </TableCell>
                                                        <TableCell width="15%">
                                                          <Typography
                                                            sx={{ fontWeight: 600 }}
                                                          >
                                                            Add
                                                          </Typography>
                                                        </TableCell>
                                                        <TableCell width="15%">
                                                          <Typography
                                                            sx={{ fontWeight: 600 }}
                                                          >
                                                            Update
                                                          </Typography>
                                                        </TableCell>
                                                        <TableCell width="15%">
                                                          <Typography
                                                            sx={{ fontWeight: 600 }}
                                                          >
                                                            Delete
                                                          </Typography>
                                                        </TableCell>
                                                        <TableCell width="15%">
                                                          <Typography
                                                            sx={{ fontWeight: 600 }}
                                                          >
                                                            Import
                                                          </Typography>
                                                        </TableCell>
                                                        <TableCell width="15%">
                                                          <Typography
                                                            sx={{ fontWeight: 600 }}
                                                          >
                                                            Export
                                                          </Typography>
                                                        </TableCell>
                                                      </TableHead> :
                                                      <TableHead>
                                                        <TableCell width="15%">
                                                          <Typography
                                                            sx={{ fontWeight: 600 }}>
                                                            Menu
                                                          </Typography>
                                                        </TableCell>
                                                        <TableCell width="15%">
                                                          <Typography
                                                            sx={{ fontWeight: 600 }}>
                                                            View
                                                          </Typography>
                                                        </TableCell>
                                                        <TableCell width="15%">
                                                          <Typography
                                                            sx={{ fontWeight: 600 }}>
                                                            Export
                                                          </Typography>
                                                        </TableCell>
                                                        <TableCell width="15%">
                                                        </TableCell>
                                                        <TableCell width="15%">
                                                        </TableCell>
                                                        <TableCell width="15%">
                                                        </TableCell>
                                                        <TableCell width="15%">
                                                        </TableCell>
                                                      </TableHead>
                                                  )}
                                                <TableBody>
                                                  {child.parentMenuCode !== 'REPORT' ?
                                                    <TableRow>
                                                      <TableCell width="15%">
                                                        <Typography
                                                          sx={{
                                                            fontSize:
                                                              phone2 &&
                                                              "10px !important",
                                                          }}
                                                        >
                                                          {child.name}
                                                        </Typography>
                                                      </TableCell>
                                                      <TableCell width="15%">
                                                        <Checkbox
                                                          name={`${item}.${index}.hasViewAccess`}
                                                          onChange={(e) => {
                                                            handlePropsChange(
                                                              e,
                                                              index,
                                                              "hasViewAccess",
                                                              child.parentMenuCode,
                                                              mainIndex
                                                            );
                                                            handlePropsUpdate(
                                                              child.displayOrder,
                                                              child.menuId,
                                                              "hasViewAccess",
                                                              e,
                                                              child.parentMenuCode,
                                                              item.code,
                                                              child.name // children node name
                                                            );
                                                          }}
                                                          checked={
                                                            child.hasViewAccess
                                                          }
                                                        />
                                                      </TableCell>
                                                      <TableCell width="15%">
                                                        <Checkbox
                                                          name={`${item}.${index}.hasInsertAccess`}
                                                          checked={
                                                            child.hasInsertAccess
                                                          }
                                                          onChange={(e) => {
                                                            handlePropsChange(
                                                              e,
                                                              index,
                                                              "hasInsertAccess",
                                                              child.parentMenuCode,
                                                              mainIndex
                                                            );

                                                            handlePropsUpdate(
                                                              child.displayOrder,
                                                              child.menuId,
                                                              "hasInsertAccess",
                                                              e
                                                            );
                                                          }}
                                                        />
                                                      </TableCell>
                                                      <TableCell width="15%">
                                                        <Checkbox
                                                          name={`${item}.${index}.hasUpdateAccess`}
                                                          onChange={(e) => {
                                                            handlePropsChange(
                                                              e,
                                                              index,
                                                              "hasUpdateAccess",
                                                              child.parentMenuCode,
                                                              mainIndex
                                                            );
                                                            handlePropsUpdate(
                                                              child.displayOrder,
                                                              child.menuId,
                                                              "hasUpdateAccess",
                                                              e
                                                            );
                                                          }}
                                                          checked={
                                                            child.hasUpdateAccess
                                                          }
                                                        />
                                                      </TableCell>
                                                      <TableCell width="15%">
                                                        <Checkbox
                                                          name={`${item}.${index}.hasDeleteAccess`}
                                                          onChange={(e) => {
                                                            handlePropsChange(
                                                              e,
                                                              index,
                                                              "hasDeleteAccess",
                                                              child.parentMenuCode,
                                                              mainIndex
                                                            );
                                                            handlePropsUpdate(
                                                              child.displayOrder,
                                                              child.menuId,
                                                              "hasDeleteAccess",
                                                              e
                                                            );
                                                          }}
                                                          checked={
                                                            child.hasDeleteAccess
                                                          }
                                                        />
                                                      </TableCell>
                                                      <TableCell width="15%">
                                                        <Checkbox
                                                          name={`${item}.${index}.hasImportAccess`}
                                                          onChange={(e) => {
                                                            handlePropsChange(
                                                              e,
                                                              index,
                                                              "hasImportAccess",
                                                              child.parentMenuCode,
                                                              mainIndex
                                                            );
                                                            handlePropsUpdate(
                                                              child.displayOrder,
                                                              child.menuId,
                                                              "hasImportAccess",
                                                              e
                                                            );
                                                          }}
                                                          checked={
                                                            child.hasImportAccess
                                                          }
                                                        />
                                                      </TableCell>
                                                      <TableCell width="15%">
                                                        <Checkbox
                                                          name={`${item}.${index}.hasExportAccess`}
                                                          onChange={(e) => {
                                                            handlePropsChange(
                                                              e,
                                                              index,
                                                              "hasExportAccess",
                                                              child.parentMenuCode,
                                                              mainIndex
                                                            );
                                                            handlePropsUpdate(
                                                              child.displayOrder,
                                                              child.menuId,
                                                              "hasExportAccess",
                                                              e
                                                            );
                                                          }}
                                                          checked={
                                                            child.hasExportAccess
                                                          }
                                                        />
                                                      </TableCell>
                                                    </TableRow> :
                                                    <TableRow>
                                                      <TableCell width="15%">
                                                        <Typography
                                                          sx={{
                                                            fontSize:
                                                              phone2 &&
                                                              "10px !important",
                                                          }}
                                                        >
                                                          {child.name}
                                                        </Typography>
                                                      </TableCell>
                                                      <TableCell width="15%">
                                                        <Checkbox
                                                          name={`${item}.${index}.hasViewAccess`}
                                                          onChange={(e) => {
                                                            handlePropsChange(
                                                              e,
                                                              index,
                                                              "hasViewAccess",
                                                              child.parentMenuCode,
                                                              mainIndex
                                                            );
                                                            handlePropsUpdate(
                                                              child.displayOrder,
                                                              child.menuId,
                                                              "hasViewAccess",
                                                              e,
                                                              child.parentMenuCode,
                                                              item.code,
                                                              child.name // children node name
                                                            );
                                                          }}
                                                          checked={
                                                            child.hasViewAccess
                                                          }
                                                        />
                                                      </TableCell>
                                                      <TableCell width="15%">
                                                        <Checkbox
                                                          name={`${item}.${index}.hasExportAccess`}
                                                          onChange={(e) => {
                                                            handlePropsChange(
                                                              e,
                                                              index,
                                                              "hasExportAccess",
                                                              child.parentMenuCode,
                                                              mainIndex
                                                            );
                                                            handlePropsUpdate(
                                                              child.displayOrder,
                                                              child.menuId,
                                                              "hasExportAccess",
                                                              e
                                                            );
                                                          }}
                                                          checked={
                                                            child.hasExportAccess
                                                          }
                                                        />
                                                      </TableCell>
                                                      <TableCell width="15%">
                                                      </TableCell>
                                                      <TableCell width="15%">
                                                      </TableCell>
                                                      <TableCell width="15%">
                                                      </TableCell>
                                                      <TableCell width="15%">
                                                      </TableCell>
                                                    </TableRow>
                                                  }
                                                </TableBody>
                                              </>
                                            </Table>
                                          </TableContainer>
                                        </Collapse>
                                      )
                                  )}
                                </>


                              ))}
                          </List> :
                          <Box className="user-group-not-selected">
                            <Typography>User group is not selected.</Typography>
                          </Box>
                        }
                      </Grid>

                    </Paper>
                  </Grid>
                </Grid>
              ) : (
                <MissingConsumer consumerId={userContext.consumerId} />
              )}
            </Box>

            {userGroup.id > 0 ?
              <Grid
                container
                columnSpacing={2}
                rowSpacing={2}
                className="GridMobileView"
                paddingY={2}
                zIndex={90}>
                <Grid item xs={6} textAlign="right" >
                  <LoadingButton
                    color="primary"
                    type="submit"
                    loading={isSubmitLoading}
                    variant="contained">
                    Submit
                  </LoadingButton>
                </Grid>
              </Grid> : <></>}
          </Form>
        )}
      </Formik>
    </>
  );
}

const customStyles = {
  control: (base: any, state: any) => ({
    ...base
  }),
  menu: provided => ({ ...provided, zIndex: 9999 })
};